import React from "react";
import Nav from "../../Templates/Nav";
import Footer from "../../Templates/Footer";

import Contact from "./Contact";
function Main() {
  return (
    <>
      <div className="containerWrapper">
        <div className="elPageContentWrapper">
          <div className="elPageContent">
            <div
              data-page-element="ContentNode"
              className="pageRoot id-6Z-6K9P7D-0"
              data-slot-name="default"
            >
              <Nav />
              <Contact/>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Main;
