// Fetch stats for admin dashboard
export async function fetchAdminStats() {
    const response = await fetch("https://primeauthoritypr.com/backend/api/admin/get_stats.php");
    return await response.json();
  }
  
  // Fetch transactions list
  export async function fetchTransactions() {
    const response = await fetch("https://primeauthoritypr.com/backend/api/admin/get_transactions.php");
    return await response.json();
  }
  
  // Fetch user details (including orders and transactions)
  export async function fetchUserDetails(userId) {
    const response = await fetch(`https://primeauthoritypr.com/backend/api/admin/get_user_details.php?user_id=${userId}`);
    return await response.json();
  }
  