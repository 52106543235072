import React, { useState } from "react";

import Nav from "../../Templates/Nav";
import Footer from "../../Templates/Footer";

function Faqs() {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };


const accordian = [
  {
    question: "What sets Prime Authority PR apart from other marketing agencies?",
    answer: "We pride ourselves on our dedication to personalized solutions, expertise in multiple areas of marketing and entertainment, and our track record of delivering tangible results to our clients.",
  },
  {
    question: "Can you provide examples of clients Prime Authority PR has worked with?",
    answer: "Yes, we have had the privilege of working with notable clients which you can check on our portfolio page.",
  },
  {
    question: "Do you provide PR Services to Small Businesses?",
    answer: "Yes, we do.",
  },
  {
    question: "How does Prime Authority PR approach social media management?",
    answer: "We employ strategic tactics to enhance our clients' online presence and engagement, utilizing our expertise to tailor campaigns to their specific goals and target audiences.",
  },
  {
    question: "Can Prime Authority PR help improve my website's performance?",
    answer: "Yes, our web development services focus on creating and optimizing websites to improve user experience and search engine rankings, ultimately driving more traffic and engagement.",
  },
  {
    question: "What industries does Prime Authority PR specialize in?",
    answer: "We work across various industries, including entertainment, technology, healthcare, finance, and more, tailoring our approach to meet each industry's unique needs.",
  },
  {
    question: "How quickly can I expect results from PR campaigns?",
    answer: "Results vary based on the nature of the campaign, but many clients start seeing improved visibility and engagement within the first few weeks of our collaboration.",
  },
  {
    question: "Does Prime Authority PR offer crisis management services?",
    answer: "Yes, we provide comprehensive crisis management services to protect and restore your brand's reputation during challenging situations.",
  },
  {
    question: "Can I customize the services included in my PR package?",
    answer: "Absolutely. We work closely with our clients to customize service packages that best fit their specific goals and budgets.",
  },
  {
    question: "How does the onboarding process work at Prime Authority PR?",
    answer: "Our onboarding process is straightforward. We start with a consultation to understand your needs, then develop a tailored strategy and provide regular updates as we implement the plan.",
  },
  {
    question: "What type of content does Prime Authority PR create for clients?",
    answer: "We create a range of content, including press releases, blog articles, social media posts, and multimedia content, all designed to enhance brand visibility and engagement.",
  },
  {
    question: "Do you offer international PR services?",
    answer: "Yes, Prime Authority PR has experience with international markets, helping clients reach audiences beyond their local regions.",
  }
];


  return (
    <>
    <Nav/>

      <div className="team_main">
        <h1 className="world_1">
          FREQUENTLY ASKED
          <span className="world_2"> QUESTIONS</span>
        </h1>
        <p className="world_p">
          Find the answers for the most frequently asked questions about
          Prime Authority PR Services
        </p>
        <div className="accordion">
          {accordian.map((item, index) => (
            <div key={index} className="accordion-item">
              <div
                className={`accordion-header ${activeIndex === index ? "open" : ""}`}
                
                onClick={() => handleToggle(index)}
              >
                <p>{item.question}</p>
                <span
                  className={`icon ${activeIndex === index ? "rotate" : ""}`}
                >
                  +
                </span>
              </div>
              <div
                className={`accordion-content ${
                  activeIndex === index ? "show" : ""
                }`}
              >
                <p>{item.answer}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      
      <Footer/>
    </>
  );
}

export default Faqs;
