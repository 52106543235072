import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";


import Nav from "../../Templates/Nav";
import Footer from "../../Templates/Footer";

function Projects() {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(
          "https://primeauthoritypr.com//backend/api/getPortfolio.php",
          {
            params: {
              api_key: "16d79c21-6910-4a11-a68f-37d5cfde1b86", 
            },
          }
        );
        setProjects(response.data);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch projects");
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  if (loading) {
    return (
      <>
      <Nav />
      <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        textAlign: "center"
      }}>

        Loading projects...

      </div>
      
      <Footer/>
      </>
    );
  }
  
  if (error) {
    return (
      <><Nav /><div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        textAlign: "center"
      }}>
        {error}
      </div><Footer /></>
    );
  }
  

  return (
    <>
    <Nav />
    <div className="projects_main">
      <h1 className="world_1">
        Our <span className="world_2"> Portfolio</span>
      </h1>
      <p className="world_p">We are very happy about our completed projects</p>

      <div className="projects_1 k1-update">
        {projects.map((project) => (
          <Link
            className="project_im"
            to={`/case-study/${project.slug}`}
            onClick={handleClick}
            key={project.id}
          >
            <div className="port">
              <div className="opacity"></div>
              <h1>{project.name}</h1>
              <img className="kimg" src={project.image} alt={project.name} />
            </div>
          </Link>
        ))}
      </div>
    </div>
    <Footer/>
    
    </>
  );
}

export default Projects;
