import React, { useState, useRef, useEffect } from "react";
// import FaqSale from "./FaqSale";

// import Logo from "../assest/salelogo.png";
import Logo from "../../assest/Prime Authority Logo v2.png"

import Footer from "../../Templates/Footer";


import Sample from "../../assest/sample.webp";
import Real from "../../assest/real.avif";

import Call from "../../assest/phone-call...png";



const faqData = [
  {
    question: "What is the turnaround time for publishing my article?",
    answer:
      "We guarantee that your article will be published within 24 hours of submission.",
  },
  {
    question: "What happens if my article is not published within 24 hours?",
    answer:
      "We offer a 100% money-back guarantee if we fail to publish your article within the promised timeframe.",
  },
  {
    question: "Why is press coverage important for my business?",
    answer:
      "Press coverage builds credibility and authority, enhances brand visibility, and attracts potential customers. Featured articles in reputable outlets boost your reputation, provide third-party validation, and establish trust. Additionally, press coverage improves search engine rankings and drives traffic to your website, contributing to business growth and success.",
  },
  {
    question: "Who writes the articles?",
    answer:
      "After placing your order, you will receive a questionnaire to provide details about your business or personal story. Our team of experienced writers will use this information to craft compelling articles that adhere to the highest editorial standards and the specific guidelines of the news publication.",
  },
  {
    question: "Are there any long-term contracts?",
    answer:
      "No long-term contracts are required for the initial article. However, we do offer monthly retainer packages for ongoing PR and reputation management services.",
  },
  {
    question: "How can I track the status of my article?",
    answer:
      "You will receive a confirmation email once your article is published. For any inquiries, you can contact our support team at any time.",
  },
  {
    question: "Can I see examples of published articles?",
    answer:
      "Yes, we can provide samples of our previously published articles upon request. This will give you an idea of the quality and style of our work.",
  },
  {
    question: "How do I get started?",
    answer:
      "Click the 'Act Now to Secure Your Placement!' button to proceed to the secure checkout and complete your order. Our team will then contact you to gather the necessary information and begin the process.",
  },
  {
    question: "Will this feature guarantee me sales?",
    answer:
      "While press coverage significantly boosts your brand's visibility and credibility, it does not guarantee sales. It attracts potential customers and establishes trust, which are crucial factors for driving sales. However, the ultimate success depends on various factors, including your product, market demand, and overall marketing strategy.",
  },

  {
    question: 'What are the benefits of this offer?',
    answer: '"As Seen On" Credibility: You can proudly display "as seen on" logos on your website, enhancing your brand\'s credibility. Authority in Your Niche: Being featured in reputable news outlets positions you as a higher authority in your niche. Increased Traffic and Conversions: Press coverage boosts traffic to your website and can improve conversion rates for your business.'
  },


];


const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};



const FaqSale = () => {
  return (
    <div className="faq-section_main">
      <div className="faq-section">
        <p className="faq-header">STILL GOT QUESTIONS? WE GOT YOU!</p>
        <h2 className="faq-title">Frequently Asked Questions</h2>
        <div className="faq-container">
          {faqData.map((faq, index) => (
            <div className="faq-item" key={index}>
              <div className="faq-question">
                <span>
                  <img
                    src="https://tuk-cdn.s3.amazonaws.com/can-uploader/question__mar-removebg-preview.png"
                    alt="?"
                    className="qmarks"
                  />{" "}
                  {faq.question}
                </span>
              </div>
              <div className="faq-answer">{faq.answer}</div>
            </div>
          ))}
        </div>
        <br />
        <button onClick={scrollToTop} className="cta-button mt3">
          Click Here To Secure Your Placement!
        </button>
      </div>
    </div>
  );
};



function Sales() {
  const [isOpen, setIsOpen] = useState(false);



  const products = [
    {
      name: "Clout News Feature",
      desc: "Get a full featured Clout News article",
      price: 349,
      isBestSelling: false,
      logo: "https://statics.myclickfunnels.com/workspace/vDOaLq/image/3167952/file/100c47c0ea0b0d358029695b6355112b.jpg"
    },

    // Add more products here as needed
  ];



  // const [selectedCountry, setSelectedCountry] = useState({
  //   code: "🇵🇰",
  //   name: "Pakistan",
  //   flag: Call,
  // });

  // const countries = [
  //   { code: "🇵🇰", name: "Pakistan", flag: Call },
  //   { code: "🇮🇳", name: "India", flag: Call },
  //   { code: "🇺🇸", name: "United States", flag: Call },
  //   { code: "🇬🇧", name: "United Kingdom", flag: Call },
  //   { code: "🇩🇪", name: "Germany", flag: Call },
  // ];

  // const handleCountrySelect = (country) => {
  //   setSelectedCountry(country);
  //   setIsOpen(false);
  // };

  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  //

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <>
      <div className="bgblack">
        <img src={Logo} alt="Logo" />
      </div>
      <div className="bgsales">
        <div className="sale_1">
          Hurry! Limited slots available for <b>guaranteed</b> media placements
          with Prime Authority PR. Secure your spot today and elevate your brand
          with
          <b>24hr publishing</b>!
        </div>
        <div className="sale_2">
          <h1>
            $349 Exclusive Press Feature - Get Featured In The News Today!
            Limited Time Offer - 100% Money-Back Guarantee!
          </h1>



          
          <div className="sale_3">


            <div className="sale_4">
              {/* <p>SAMPLE ARTICLE</p> */}
              <img src={Sample} alt="Sample" />
              <a href="https://cloutnews.com/saqib-malik-launches-prime-authority-pr-with-guaranteed-media-coverage/" className="opensample">
                Open Sample
              </a>
              <div className="sale_4_1">
                Getting Featured In Press Helped Me Get My Socials Marked
                Authentic!
              </div>
              <img src={Real} alt="Real" className="real_saqib" />
              <div className="sale_4_1">Let's Break It All Down...</div>
              <div className="heres">HERE'S WHAT YOU ARE REALLY GETTING...</div>
              <div className="sale_4_2">
                <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/ticks_1234.png" />
                Custom written article and publishing ($1500 value)
              </div>
              <div className="sale_4_2">
                <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/ticks_1234.png" />
                A calendar link to book a free press and reputation management
                strategy session with me worth $500
              </div>
              <div className="sale_4_2">
                <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/ticks_1234.png" />
                Higher chance of getting your social media pages marked
                authentic
              </div>
              <div className="sale_4_2">
                <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/ticks_1234.png" />
                More satisfied customers, more referrals, more free time! (You
                can't put a value on a better quality of life!)
              </div>
              <div className="opensample" style={{ textAlign: "center" }}>
                That's $2000 worth of value for .... $349 ONLY! <br /> ​START
                NOW!
              </div>
            </div>


            {/* Payment form */}

            <div className="sale_5">
              <form className="form">
                <div className="section">
                  <h2 className="c_i">Contact Information</h2>
                  <div className="form-group">
                    <label htmlFor="firstName">First Name</label>
                    <input
                      type="text"
                      id="firstName"
                      className="input-field errors"
                      placeholder="First Name"
                    />
                    {/* <div className="error-message">First Name is required</div> */}
                  </div>
                  <div className="form-group">
                    <label htmlFor="lastName">Last Name</label>
                    <input
                      type="text"
                      id="lastName"
                      className="input-field"
                      placeholder="Last Name"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      className="input-field"
                      placeholder="Enter Your Email Address"
                    />
                  </div>
                  <div className="form-group" ref={dropdownRef}>
                    <label htmlFor="phoneNumber">Phone Number</label>
                    <div className="phone-group">
                      {/* <div
                        className="country-code"
                        onClick={() => setIsOpen((prev) => !prev)}
                      >
                        <img
                          src={selectedCountry.flag}
                          alt={`${selectedCountry.name} flag`}
                          style={{ width: "15px", marginRight: "5px" }}
                        />
                        {selectedCountry.code}
                      </div> */}
                      {/* {isOpen && (
                        <div className="dropdown-menu">
                          {countries.map((country) => (
                            <div
                              key={country.code}
                              className="dropdown-item"
                              onClick={() => handleCountrySelect(country)}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                padding: "5px",
                              }}
                            >
                              <img
                                src={country.flag}
                                alt={`${country.name} flag`}
                                style={{ width: "15px", marginRight: "5px" }}
                              />
                              {country.code}
                            </div>
                          ))}
                        </div>
                      )} */}
                      <input
                        type="text"
                        id="phoneNumber"
                        className="input-field"
                        placeholder="Enter Your phone number"
                      />
                    </div>
                  </div>
                </div>


                <div className="section">
                  <h2 className="c_i">Select Product</h2>
                  {products.map((product, index) => (
                    <div key={index} className="product">
                      <img src={product.logo} alt={product.name} className="product-logo" />
                      <div className="product-info">
                      {product.isBestSelling && <p className="product-title">BEST SELLING</p>}
                      <p className="c_i">{product.name}</p>
                        <p className="c_i">{product.desc}</p>
                      </div>
                      <div className="product-price">
                        <p>${product.price.toFixed(2)}</p>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="section">
                  <h2 className="c_i">Billing Information</h2>
                  <div className="form-group">
                    <label htmlFor="address">Address</label>
                    <input
                      type="text"
                      id="address"
                      className="input-field"
                      placeholder="Apartment, building, floor (optional)"
                    />
                  </div>

                  <div className="form-group ">
                    <label htmlFor="postalCode">State</label>
                    <input
                      type="text"
                      id="state"
                      className="input-field"
                      placeholder="State"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="country">Country</label>
                    <select id="country" className="input-field">
                      <option value="Afghanistan">Afghanistan</option>
                      <option value="Albania">Albania</option>
                      <option value="Algeria">Algeria</option>
                      <option value="Andorra">Andorra</option>
                      <option value="Angola">Angola</option>
                      <option value="Antigua and Barbuda">
                        Antigua and Barbuda
                      </option>
                      <option value="Argentina">Argentina</option>
                      <option value="Armenia">Armenia</option>
                      <option value="Australia">Australia</option>
                      <option value="Austria">Austria</option>
                      <option value="Azerbaijan">Azerbaijan</option>
                      <option value="Bahamas">Bahamas</option>
                      <option value="Bahrain">Bahrain</option>
                      <option value="Bangladesh">Bangladesh</option>
                      <option value="Barbados">Barbados</option>
                      <option value="Belarus">Belarus</option>
                      <option value="Belgium">Belgium</option>
                      <option value="Belize">Belize</option>
                      <option value="Benin">Benin</option>
                      <option value="Bhutan">Bhutan</option>
                      <option value="Bolivia">Bolivia</option>
                      <option value="Bosnia and Herzegovina">
                        Bosnia and Herzegovina
                      </option>
                      <option value="Botswana">Botswana</option>
                      <option value="Brazil">Brazil</option>
                      <option value="Brunei">Brunei</option>
                      <option value="Bulgaria">Bulgaria</option>
                      <option value="Burkina Faso">Burkina Faso</option>
                      <option value="Burundi">Burundi</option>
                      <option value="Cabo Verde">Cabo Verde</option>
                      <option value="Cambodia">Cambodia</option>
                      <option value="Cameroon">Cameroon</option>
                      <option value="Canada">Canada</option>
                      <option value="Central African Republic">
                        Central African Republic
                      </option>
                      <option value="Chad">Chad</option>
                      <option value="Chile">Chile</option>
                      <option value="China">China</option>
                      <option value="Colombia">Colombia</option>
                      <option value="Comoros">Comoros</option>
                      <option value="Congo (Congo-Brazzaville)">
                        Congo (Congo-Brazzaville)
                      </option>
                      <option value="Congo (DRC)">Congo (DRC)</option>
                      <option value="Costa Rica">Costa Rica</option>
                      <option value="Croatia">Croatia</option>
                      <option value="Cuba">Cuba</option>
                      <option value="Cyprus">Cyprus</option>
                      <option value="Czechia">Czechia</option>
                      <option value="Denmark">Denmark</option>
                      <option value="Djibouti">Djibouti</option>
                      <option value="Dominica">Dominica</option>
                      <option value="Dominican Republic">
                        Dominican Republic
                      </option>
                      <option value="Ecuador">Ecuador</option>
                      <option value="Egypt">Egypt</option>
                      <option value="El Salvador">El Salvador</option>
                      <option value="Equatorial Guinea">
                        Equatorial Guinea
                      </option>
                      <option value="Eritrea">Eritrea</option>
                      <option value="Estonia">Estonia</option>
                      <option value="Eswatini">Eswatini</option>
                      <option value="Ethiopia">Ethiopia</option>
                      <option value="Fiji">Fiji</option>
                      <option value="Finland">Finland</option>
                      <option value="France">France</option>
                      <option value="Gabon">Gabon</option>
                      <option value="Gambia">Gambia</option>
                      <option value="Georgia">Georgia</option>
                      <option value="Germany">Germany</option>
                      <option value="Ghana">Ghana</option>
                      <option value="Greece">Greece</option>
                      <option value="Grenada">Grenada</option>
                      <option value="Guatemala">Guatemala</option>
                      <option value="Guinea">Guinea</option>
                      <option value="Guinea-Bissau">Guinea-Bissau</option>
                      <option value="Guyana">Guyana</option>
                      <option value="Haiti">Haiti</option>
                      <option value="Honduras">Honduras</option>
                      <option value="Hungary">Hungary</option>
                      <option value="Iceland">Iceland</option>
                      <option value="India">India</option>
                      <option value="Indonesia">Indonesia</option>
                      <option value="Iran">Iran</option>
                      <option value="Iraq">Iraq</option>
                      <option value="Ireland">Ireland</option>
                      <option value="Israel">Israel</option>
                      <option value="Italy">Italy</option>
                      <option value="Jamaica">Jamaica</option>
                      <option value="Japan">Japan</option>
                      <option value="Jordan">Jordan</option>
                      <option value="Kazakhstan">Kazakhstan</option>
                      <option value="Kenya">Kenya</option>
                      <option value="Kiribati">Kiribati</option>
                      <option value="Kuwait">Kuwait</option>
                      <option value="Kyrgyzstan">Kyrgyzstan</option>
                      <option value="Laos">Laos</option>
                      <option value="Latvia">Latvia</option>
                      <option value="Lebanon">Lebanon</option>
                      <option value="Lesotho">Lesotho</option>
                      <option value="Liberia">Liberia</option>
                      <option value="Libya">Libya</option>
                      <option value="Liechtenstein">Liechtenstein</option>
                      <option value="Lithuania">Lithuania</option>
                      <option value="Luxembourg">Luxembourg</option>
                      <option value="Madagascar">Madagascar</option>
                      <option value="Malawi">Malawi</option>
                      <option value="Malaysia">Malaysia</option>
                      <option value="Maldives">Maldives</option>
                      <option value="Mali">Mali</option>
                      <option value="Malta">Malta</option>
                      <option value="Marshall Islands">Marshall Islands</option>
                      <option value="Mauritania">Mauritania</option>
                      <option value="Mauritius">Mauritius</option>
                      <option value="Mexico">Mexico</option>
                      <option value="Micronesia">Micronesia</option>
                      <option value="Moldova">Moldova</option>
                      <option value="Monaco">Monaco</option>
                      <option value="Mongolia">Mongolia</option>
                      <option value="Montenegro">Montenegro</option>
                      <option value="Morocco">Morocco</option>
                      <option value="Mozambique">Mozambique</option>
                      <option value="Myanmar">Myanmar</option>
                      <option value="Namibia">Namibia</option>
                      <option value="Nauru">Nauru</option>
                      <option value="Nepal">Nepal</option>
                      <option value="Netherlands">Netherlands</option>
                      <option value="New Zealand">New Zealand</option>
                      <option value="Nicaragua">Nicaragua</option>
                      <option value="Niger">Niger</option>
                      <option value="Nigeria">Nigeria</option>
                      <option value="North Korea">North Korea</option>
                      <option value="North Macedonia">North Macedonia</option>
                      <option value="Norway">Norway</option>
                      <option value="Oman">Oman</option>
                      <option value="Pakistan">Pakistan</option>
                      <option value="Palau">Palau</option>
                      <option value="Panama">Panama</option>
                      <option value="Papua New Guinea">Papua New Guinea</option>
                      <option value="Paraguay">Paraguay</option>
                      <option value="Peru">Peru</option>
                      <option value="Philippines">Philippines</option>
                      <option value="Poland">Poland</option>
                      <option value="Portugal">Portugal</option>
                      <option value="Qatar">Qatar</option>
                      <option value="Romania">Romania</option>
                      <option value="Russia">Russia</option>
                      <option value="Rwanda">Rwanda</option>
                      <option value="Saint Kitts and Nevis">
                        Saint Kitts and Nevis
                      </option>
                      <option value="Saint Lucia">Saint Lucia</option>
                      <option value="Saint Vincent and the Grenadines">
                        Saint Vincent and the Grenadines
                      </option>
                      <option value="Samoa">Samoa</option>
                      <option value="San Marino">San Marino</option>
                      <option value="Sao Tome and Principe">
                        Sao Tome and Principe
                      </option>
                      <option value="Saudi Arabia">Saudi Arabia</option>
                      <option value="Senegal">Senegal</option>
                      <option value="Serbia">Serbia</option>
                      <option value="Seychelles">Seychelles</option>
                      <option value="Sierra Leone">Sierra Leone</option>
                      <option value="Singapore">Singapore</option>
                      <option value="Slovakia">Slovakia</option>
                      <option value="Slovenia">Slovenia</option>
                      <option value="Solomon Islands">Solomon Islands</option>
                      <option value="Somalia">Somalia</option>
                      <option value="South Africa">South Africa</option>
                      <option value="South Korea">South Korea</option>
                      <option value="South Sudan">South Sudan</option>
                      <option value="Spain">Spain</option>
                      <option value="Sri Lanka">Sri Lanka</option>
                      <option value="Sudan">Sudan</option>
                      <option value="Suriname">Suriname</option>
                      <option value="Sweden">Sweden</option>
                      <option value="Switzerland">Switzerland</option>
                      <option value="Syria">Syria</option>
                      <option value="Taiwan">Taiwan</option>
                      <option value="Tajikistan">Tajikistan</option>
                      <option value="Tanzania">Tanzania</option>
                      <option value="Thailand">Thailand</option>
                      <option value="Timor-Leste">Timor-Leste</option>
                      <option value="Togo">Togo</option>
                      <option value="Tonga">Tonga</option>
                      <option value="Trinidad and Tobago">
                        Trinidad and Tobago
                      </option>
                      <option value="Tunisia">Tunisia</option>
                      <option value="Turkey">Turkey</option>
                      <option value="Turkmenistan">Turkmenistan</option>
                      <option value="Tuvalu">Tuvalu</option>
                      <option value="Uganda">Uganda</option>
                      <option value="Ukraine">Ukraine</option>
                      <option value="United Arab Emirates">
                        United Arab Emirates
                      </option>
                      <option value="United Kingdom">United Kingdom</option>
                      <option value="United States">United States</option>
                      <option value="Uruguay">Uruguay</option>
                      <option value="Uzbekistan">Uzbekistan</option>
                      <option value="Vanuatu">Vanuatu</option>
                      <option value="Vatican City">Vatican City</option>
                      <option value="Venezuela">Venezuela</option>
                      <option value="Vietnam">Vietnam</option>
                      <option value="Yemen">Yemen</option>
                      <option value="Zambia">Zambia</option>
                      <option value="Zimbabwe">Zimbabwe</option>
                    </select>
                  </div>

                  <div className="form-group half">
                    <label htmlFor="city">City</label>
                    <input
                      type="text"
                      id="city"
                      className="input-field"
                      placeholder="City"
                    />
                  </div>
                  <div className="form-group half">
                    <label htmlFor="postalCode">Postal Code</label>
                    <input
                      type="text"
                      id="postalCode"
                      className="input-field"
                      placeholder="Postal Code"
                    />
                  </div>
                </div>
                <div className="section">
                  <h2 className="c_i">Payment Information</h2>
                  <div className="form-group half">
                    <input
                      type="text"
                      className="input-field"
                      placeholder="Card number"
                    />
                  </div>
                  <div className="form-group half">
                    <input
                      type="text"
                      className="input-field"
                      placeholder="MM / YY"
                    />
                  </div>
                  <div className="form-group ">
                    <input
                      type="text"
                      className="input-field"
                      placeholder="CVV"
                    />
                  </div>
                </div>
                <div className="submit-section">
                  <button type="submit" className="submit-btn">
                    Submit Order
                    <br />
                    Get Me Published Today!
                  </button>
                </div>
                <p className="disclaimer">
                  We Never Share Your Information With Anyone
                </p>
              </form>
              <div className="c_i mt3">
                <b>14 Days Guarantee</b>: You have a 14 Days money back
                guarantee. Don't like our service? Let us know during the first
                2 weeks and you will get refunded 100% Rock Solid Guarantee.
              </div>
              <div className="c_i mt3">
                <b>Secure Payments</b>: All orders are through a very secure
                network. Your Credit or Debit Card information is never stored
                in any way. We respect your privacy.
              </div>
              <div className="c_i mt3">
                <b>Guaranteed Delivery</b>: After the payment is processed you
                will receive an email with a copy of a sample article and a
                questionnaire, which you can fill or modify outlining your story
                and send over for guaranteed publishing within 24 hours.
              </div>
            </div>













          </div>
        </div>
      </div>


   
      <div className="testimonials-section">
        <h3 className="section-title">Customer Testimonials</h3>
        <h1 className="section-subtitle">
          What Our Past Clients Have To Say About Us!
        </h1>
        <div className="testimonials-container">
          {/* Testimonial 1 */}
          <div className="testimonial">
            <img
              src="https://images.clickfunnels.com/cdn-cgi/image/width=2600,fit=scale-down,f=auto,q=80/https://statics.myclickfunnels.com/workspace/vDOaLq/image/3168261/file/0b7f44d190d77925472848810271b76d.jpeg"
              alt="Sean Borg"
              className="testimonial-image"
            />
            <p className="testimonial-text">
              "Over the years, Saqib has worked with me on everything from
              press, reputation management, brand design, online and print.
              Projects are always approached with enthusiasm, care and a focus
              to deliver on-time and with the agreed budget."
            </p>
            <p className="testimonial-author">- Sean Borg</p>
          </div>
          {/* Testimonial 2 */}
          <div className="testimonial">
            <img
              src="https://images.clickfunnels.com/cdn-cgi/image/width=2600,fit=scale-down,f=auto,q=80/https://statics.myclickfunnels.com/workspace/vDOaLq/image/3168286/file/9760db805d5baa2921c7de1e8e926095.jpeg"
              alt="Mo Abdul"
              className="testimonial-image"
            />
            <p className="testimonial-text">
              "Saqib Malik has provided us with a comprehensive, fast and well
              planned digital marketing strategy that has yielded great results
              in terms of content, SEO, press or social media. Him and his team
              are a pleasure to work with, as well as being fast to respond and
              adapt to the needs of your brand."
            </p>
            <p className="testimonial-author">- Mo Abdul</p>
          </div>
          {/* Testimonial 3 */}
          <div className="testimonial">
            <img
              src="https://images.clickfunnels.com/cdn-cgi/image/width=2600,fit=scale-down,f=auto,q=80/https://statics.myclickfunnels.com/workspace/vDOaLq/image/3168289/file/dd5a967dec10da728e0780f2025166c0.jpeg"
              alt="Rishi Jain"
              className="testimonial-image"
            />
            <p className="testimonial-text">
              "Saqib Malik's company is the most professional social media
              management company. They fulfilled my expectations as a client. In
              the past I have dealt with some shoddy service providers who did
              not meet the requirements. Saqib's company delivered their
              services in timely and professional fashion. I would definitely
              recommend them to any of my colleagues."
            </p>
            <p className="testimonial-author">- Rishi Jain</p>
          </div>
        </div>
        <button onClick={scrollToTop} className="cta-button">
          Click Here To Secure Your Placement!
        </button>
      </div>


      <FaqSale />
      <Footer/>
    </>
  );
}

export default Sales;
