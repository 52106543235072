import React from "react";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import SEO from "./Components/Seo";

import Nav from "./Templates/Nav";
import Footer from "./Templates/Footer";

// Import user-facing pages
import Home from "./Pages/Home/Main";
import About from "./Pages/About/Main";
import Contact from "./Pages/Contact/Main";
import CaseStudies from "./Pages/portfolio/Projects";  // Consider renaming file later
import CaseStudyDetail from "./Pages/portfolio/ProjectDetail";  // Consider renaming file later
import Terms from "./Pages/Legal/Terms";
import Privacy from "./Pages/Legal/Privacy";
import Faqs from "./Pages/Faqs/Faqs";
import Price from "./Pricing/Price";

// Funnel Pages
import Thankyou from "./Funnel/Thanks/Thankyou";
import Sale from "./Funnel/sale/sales";
import SalesNew from "./Funnel/sale/salesnew";
import TheUBJSale from "./Funnel/TheUBJ/theubj";
import Tony from "./Funnel/TimesOfNewYork/tont";
import TikTokSale from "./Funnel/TheUBJ/tiktok";

// Login Pages
import Login from "./loginform/Login";
import Register from "./loginform/Register";
import ForgotPassword from "./loginform/ForgotPassword";

// Admin Area
import AdminArea from "./AdminArea/AdminArea";
import Orders from "./AdminArea/Orders";
import Users from "./AdminArea/Users";
import UserDetails from "./AdminArea/UserDetails";
import OrderDetails from "./AdminArea/OrderDetails";
import TransactionsPage from "./AdminArea/TransactionsPage";

// Authentication Check
import { isAdminAuthenticated, isUserAuthenticated } from "./auth";

import "./App.css";
import "./index.css";
import UserArea from "./UserArea/UserArea";

function App() {
  return (
    <HelmetProvider>
      <div className="App">
        <Router>
          <Routes>
            {/* Public Routes */}
            <Route
              path="/"
              element={
                <>
                  <SEO 
                    title="Guaranteed Press Coverage" 
                    description="Welcome to our home page." 
                    keywords="home, main page" 
                  />
                  <Home />
                </>
              }
            />
            <Route
              path="/about"
              element={
                <>
                  <SEO 
                    title="About Us" 
                    description="Learn more about us." 
                    keywords="about, company" 
                  />
                  <About />
                </>
              }
            />
            <Route
              path="/contact"
              element={
                <>
                  <SEO 
                    title="Contact Us" 
                    description="Get in touch with us." 
                    keywords="contact, reach us" 
                  />
                  <Contact />
                </>
              }
            />

            {/* Case Study Routes */}
            <Route
              path="/case-study"
              element={
                <>
                  <SEO 
                    title="Case Studies" 
                    description="View our success stories and case studies." 
                    keywords="case studies, success stories, projects" 
                  />
                  <CaseStudies />
                </>
              }
            />
            <Route
              path="/case-study/:slug"
              element={
                <>
                  <SEO 
                    title="Case Study Detail" 
                    description="Detailed view of our success story." 
                    keywords="case study, project detail, success story" 
                  />
                  <CaseStudyDetail />
                </>
              }
            />

            {/* Redirect old portfolio URLs to case study */}
            <Route
              path="/portfolio"
              element={<Navigate to="/case-study" replace />}
            />
            <Route
              path="/portfolio/:slug"
              element={<Navigate to="/case-study/:slug" replace />}
            />

            {/* Login and Registration Pages */}
            <Route
              path="/login"
              element={
                <>
                  <SEO 
                    title="Login" 
                    description="Access your account." 
                    keywords="login, sign in" 
                  />
                  <Login />
                </>
              }
            />
            <Route
              path="/register"
              element={
                <>
                  <SEO 
                    title="Register" 
                    description="Create a new account." 
                    keywords="register" 
                  />
                  <Register />
                </>
              }
            />
            <Route
              path="/forgot-password"
              element={
                <>
                  <SEO 
                    title="Forgot Password" 
                    description="Reset your password." 
                    keywords="forgot password" 
                  />
                  <ForgotPassword />
                </>
              }
            />

            {/* Funnel Pages */}
            <Route
              path="/sale"
              element={
                <>
                  <SEO 
                    title="Sale" 
                    description="Explore our sales page." 
                    keywords="sale, offers" 
                  />
                  <Sale />
                </>
              }
            />
            <Route
              path="/salenew"
              element={
                <>
                  <SEO 
                    title="Clout Sale" 
                    description="Explore our new sales page." 
                    keywords="sale, offers" 
                  />
                  <SalesNew />
                </>
              }
            />
            <Route
              path="/theubj"
              element={
                <>
                  <SEO 
                    title="The UBJ Sale" 
                    description="Get featured in The United Business Journal." 
                    keywords="sale, offers" 
                  />
                  <TheUBJSale />
                </>
              }
            />

              <Route
              path="/tiktok"
              element={
                <>
                  <SEO 
                    title="TikTok Sale" 
                    description="Get featured in 3 Major publications." 
                    keywords="sale, offers" 
                  />
                  <TikTokSale />
                </>
              }
            />


            <Route
              path="/tony"
              element={
                <>
                  <SEO 
                    title="Times Of New York Sale" 
                    description="Get featured in Times Of New York." 
                    keywords="sale, offers" 
                  />
                  <Tony />
                </>
              }
            />

            {/* Legal Pages */}
            <Route
              path="/terms"
              element={
                <>
                  <SEO 
                    title="Terms of Service" 
                    description="Read our terms." 
                    keywords="terms, policy" 
                  />
                  <Terms />
                </>
              }
            />
            <Route
              path="/privacy"
              element={
                <>
                  <SEO 
                    title="Privacy Policy" 
                    description="Learn about our privacy practices." 
                    keywords="privacy, data protection" 
                  />
                  <Privacy />
                </>
              }
            />
            <Route
              path="/faqs"
              element={
                <>
                  <SEO 
                    title="FAQs" 
                    description="Frequently asked questions." 
                    keywords="faq, help" 
                  />
                  <Faqs />
                </>
              }
            />
            <Route
              path="/thanks"
              element={
                <>
                  <SEO 
                    title="Thank You" 
                    description="Thank you for your business." 
                    keywords="thank you, response" 
                  />
                  <Thankyou />
                </>
              }
            />
            <Route
              path="/pricelist"
              element={
                <>
                  <SEO 
                    title="Pricing" 
                    description="View our pricing plans." 
                    keywords="pricing, costs" 
                  />
                  <Price />
                </>
              }
            />

            {/* User Area (Protected) */}
            <Route
              path="/user/*"
              element={
                isUserAuthenticated() ? (
                  <UserArea />
                ) : (
                  <Navigate to="/login" replace />
                )
              }
            />

            {/* Admin Routes (Protected) */}
            <Route
              path="/admin/*"
              element={
                isAdminAuthenticated() ? (
                  <AdminArea />
                ) : (
                  <Navigate to="/login" replace />
                )
              }
            />
            <Route
              path="/admin/orders"
              element={
                isAdminAuthenticated() ? (
                  <Orders />
                ) : (
                  <Navigate to="/login" replace />
                )
              }
            />
            <Route
              path="/admin/orders/:orderId"
              element={
                isAdminAuthenticated() ? (
                  <OrderDetails />
                ) : (
                  <Navigate to="/login" replace />
                )
              }
            />
            <Route
              path="/admin/users"
              element={
                isAdminAuthenticated() ? (
                  <Users />
                ) : (
                  <Navigate to="/login" replace />
                )
              }
            />
            <Route
              path="/admin/users/:userId"
              element={
                isAdminAuthenticated() ? (
                  <UserDetails />
                ) : (
                  <Navigate to="/login" replace />
                )
              }
            />
            <Route
              path="/admin/transactions"
              element={
                isAdminAuthenticated() ? (
                  <TransactionsPage />
                ) : (
                  <Navigate to="/login" replace />
                )
              }
            />

            {/* 404 Route */}
            <Route
              path="*"
              element={
                <>
                  <SEO 
                    title="Page Not Found" 
                    description="The page you're looking for doesn't exist." 
                    keywords="404, not found" 
                  />
                  <div>
                    <Nav/>
                    <div style={{ 
                      display: 'flex', 
                      flexDirection: 'column', 
                      alignItems: 'center', 
                      justifyContent: 'center', 
                      height: '80vh', 
                      textAlign: 'center' 
                    }}>
                      <h1>Page Not Found</h1>
                      
                    </div>
                    <Footer/>
                  </div>
                </>
              }
            />

          </Routes>
        </Router>
      </div>
    </HelmetProvider>
  );
}

export default App;