import React, { useState } from "react";
import { FaInfoCircle } from "react-icons/fa";
import publicationsData from "./publicationsData";

import Footer from "../Templates/Footer";
import Nav from "../Templates/Nav";


function Price() {
  const defaultFilters = {
    priceRange: [0, 100000],
    region: "",
    sortBy: "price",
    sortDirection: "asc",
    genres: [],
    tags: [],
    sponsored: "",
    do_follow: "",
    indexed: "",
    search: "",
  };

  const [publications] = useState(publicationsData);
  const [filters, setFilters] = useState(defaultFilters);
  const [dialogNote, setDialogNote] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const resetFilters = () => {
    setFilters(defaultFilters);
  };

  const openDialog = (note) => {
    setDialogNote(note);
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setDialogNote("");
  };

  const filteredPublications = publications
    .filter((pub) => {
      const matchesPrice =
        pub.price >= filters.priceRange[0] &&
        pub.price <= filters.priceRange[1];
      const matchesRegion =
        filters.region === "" || pub.region === filters.region;
      const matchesGenres =
        filters.genres.length === 0 ||
        filters.genres.some((genre) => pub.genres.includes(genre));
      const matchesSponsored =
        filters.sponsored === "" || pub.sponsored === filters.sponsored;
      const matchesDoFollow =
        filters.do_follow === "" || pub.do_follow === filters.do_follow;
      const matchesIndexed =
        filters.indexed === "" || pub.indexed === filters.indexed;
      const matchesSearch =
        filters.search === "" ||
        pub.name.toLowerCase().includes(filters.search.toLowerCase()); // Search logic
      return (
        matchesPrice &&
        matchesRegion &&
        matchesGenres &&
        matchesSponsored &&
        matchesDoFollow &&
        matchesIndexed &&
        matchesSearch
      );
    })
    .sort((a, b) => {
      const sortBy = filters.sortBy;
      const direction = filters.sortDirection === "asc" ? 1 : -1;
      if (sortBy === "price" || sortBy === "da" || sortBy === "dr") {
        return (a[sortBy] - b[sortBy]) * direction;
      } else if (sortBy === "genres") {
        return (a.genres.length - b.genres.length) * direction;
      } else if (sortBy === "tags") {
        return (a.tags.length - b.tags.length) * direction;
      } else {
        return 0;
      }
    });

  return (
    <div className="App">
      <Nav/>


      {/* <header className="app-header">
        <img
          src="https://images.clickfunnels.com/cdn-cgi/image/width=1000,fit=scale-down,f=auto,q=100/https://statics.myclickfunnels.com/workspace/vDOaLq/image/3154658/file/6350967ef39ba5218c76e670c57fe8f5.png"
          alt="Logo"
          className="logoss"
        />
        <div className="header-buttons">
          <button className="reset-button">Logout</button>
        </div>
      </header> */}

      <div className="main-content">
        <div className="sidebar-filters">
          <h3 className="m-none">Filters</h3>

          {/* Search Publication Filter */}
          <div className="filter-group">
            <label>Search Publication</label>
            <input
              type="text"
              name="search"
              value={filters.search}
              onChange={handleFilterChange}
              placeholder="Search by name"
            />
          </div>

          <div className="filter-group">
            <label>Price Range</label>
            <input
              type="range"
              name="priceRange"
              min="0"
              max="100000"
              value={filters.priceRange[1]}
              onChange={(e) =>
                setFilters({ ...filters, priceRange: [0, e.target.value] })
              }
            />
            <span>${filters.priceRange[1]}</span>
          </div>

          <div className="filter-group">
            <label>Region</label>
            <select name="region" onChange={handleFilterChange}>
              <option value="">Select Region</option>
              <option value="United States">United States</option>
              <option value="UAE">UAE</option>
              <option value="Australia">Australia</option>
            </select>
          </div>

          <div className="filter-group">
            <label>Sort by</label>
            <select name="sortBy" onChange={handleFilterChange}>
              <option value="price">Price</option>
              <option value="da">DA</option>
              <option value="dr">DR</option>
              <option value="tags">Tags</option>
              <option value="genres">Genres</option>
              <option value="sponsored">Sponsored</option>
              <option value="do_follow">Do Follow</option>
              <option value="indexed">Indexed</option>
            </select>
          </div>

          <div className="filter-group">
            <label>Order</label>
            <select name="sortDirection" onChange={handleFilterChange}>
              <option value="asc">Ascending</option>
              <option value="desc">Descending</option>
            </select>
          </div>

          <div className="filter-group">
            <label>Sponsored</label>
            <select name="sponsored" onChange={handleFilterChange}>
              <option value="">Select Sponsored</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
              <option value="Discrete">Discrete</option>
            </select>
          </div>

          <div className="filter-group">
            <label>Do Follow</label>
            <select name="do_follow" onChange={handleFilterChange}>
              <option value="">Select Do Follow</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>

          <div className="filter-group">
            <label>Indexed</label>
            <select name="indexed" onChange={handleFilterChange}>
              <option value="">Select Indexed</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
              <option value="Maybe">Maybe</option>
            </select>
          </div>

          <button className="reset-button" onClick={resetFilters}>
            Reset Filters
          </button>
        </div>

        {/* Publication Table */}
        <div className="publication-container mt3">
          <table className="publication-table">
            <thead>
              <tr>
                <th>Publication</th>
                <th>Genres</th>
                <th>Price</th>
                <th>DA</th>
                <th>DR</th>
                <th>TAT</th>
                <th>Region</th>
                <th>Sponsored</th>
                <th>Indexed</th>
                <th>Do Follow</th>
                <th>Sample</th>
              </tr>
            </thead>
            <tbody>
              {filteredPublications.map((pub, index) => (
                <tr key={index}>
                  <td>
                    <div className="publication-cell">
                      <img src={pub.logo} alt="Logo" className="pub-logo" />
                      <div className="publication-info">
                        <a
                          href={pub.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="pub-name"
                        >
                          {pub.name}
                        </a>
                        {pub.note && (
                          <FaInfoCircle
                            className="info-icon"
                            onClick={() => openDialog(pub.note)}
                          />
                        )}
                        <div className="tag-list">
                          {pub.tags.length > 0 ? (
                            pub.tags.map((tag) => (
                              <span
                                key={tag}
                                className={`tag ${tag
                                  .toLowerCase()
                                  .replace(" ", "-")}`}
                              >
                                {tag}
                              </span>
                            ))
                          ) : (
                            <span></span>
                          )}
                        </div>
                      </div>
                    </div>
                  </td>

                  {/* Displaying genres as tags */}
                  <td>
                    <div className="genre-list">
                      {pub.genres.map((genre, idx) => (
                        <span key={idx} className="genre-tag">
                          {genre}
                        </span>
                      ))}
                    </div>
                  </td>

                  <td>${pub.price}</td>
                  <td>{pub.da}</td>
                  <td>{pub.dr}</td>
                  <td>{pub.tat}</td>
                  <td>{pub.region}</td>
                  <td>{pub.sponsored}</td>
                  <td>{pub.indexed}</td>
                  <td>{pub.do_follow}</td>
                  <td>
                    {pub.sample_image ? (
                      <a
                        href={pub.sample_image}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={pub.sample_image}
                          alt="Sample"
                          className="sample-icon"
                        />
                      </a>
                    ) : (
                      <div className="no-sample">
                        Not Available
                      </div> /* Fallback */
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {isDialogOpen && (
        <div className="dialog-overlay" onClick={closeDialog}>
          <div className="dialog-box">
            <p>{dialogNote}</p>
            <button onClick={closeDialog}>Close</button>
          </div>
        </div>
      )}



      <Footer/>
 
      {/* <footer className="app-footer">
        <p>&copy; 2024 Prime Authority PR. All rights reserved.</p>
      </footer> */}


    </div>
  );
}

export default Price;
