import React from "react";
import { Helmet } from "react-helmet-async";
import P1 from "../assest/9c2a4bd4e8d37efa0b53f3384443e5f1.png";

const SEO = ({ title, description, keywords, shareImage = P1 }) => {
  return (
    <Helmet>
      <title>{title} | Prime Authority PR</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />

      {/* Open Graph tags for social media */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={shareImage} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={window.location.href} />

      {/* Twitter card for sharing */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={shareImage} />
    </Helmet>
  );
};

export default SEO;
