import React from "react";
function Heros() {
  return (
    <>
      <div
        data-page-element="SectionContainer/V1"
        className="container fullContainer bgW100 id-6Z-6K9P7D-2161"
        data-paint-colors="darkest"
      >
        <div className="containerInnerV2">
          <div
            data-page-element="RowContainer/V1"
            className="row id-6Z-6K9P7D-2162"
            data-slot-name="default"
          >
            <div
              data-page-element="ColContainer/V1"
              className="col-md-12 innerContent col_left id-6Z-6K9P7D-2163"
            >
              <div className="col-inner">
                <div
                  data-page-element="Headline/V1"
                  className="elHeadlineWrapper id-6Z-6K9P7D-2168"
                >
                  <h1 className="elHeadline mt3" data-style-guide-headline="xl">
                    <span>
                      <span
                        data-page-element="ContentEditableNode"
                        className="ne id-6Z-6K9P7D-2169"
                        data-align-selector=".elHeadline"
                        data-slot-name="default"
                      >
                        <span className="id-6Z-6K9P7D-2301">
                          Get Featured On The World's Leading Publications
                        </span>
                      </span>
                    </span>
                  </h1>
                </div>
                <div
                  data-page-element="Headline/V1"
                  className="elHeadlineWrapper id-6Z-6K9P7D-2281"
                >
                  <h1 className="elHeadline mt-2" data-style-guide-headline="xl">
                    <span>
                      <span
                        data-page-element="ContentEditableNode"
                        className="ne id-6Z-6K9P7D-2282"
                        data-align-selector=".elHeadline"
                        data-slot-name="default"
                      >
                        <span className="id-6Z-6K9P7D-2303">
                          <span className="id-6Z-6K9P7D-2304">
                            <br className="id-6Z-6K9P7D-2305" />
                          </span>
                          &nbsp;Guaranteed
                        </span>
                      </span>
                    </span>
                  </h1>
                </div>
                <div
                  data-page-element="Headline/V1"
                  className="elHeadlineWrapper id-6Z-6K9P7D-2187"
                >
                  <h1 className="elHeadline">
                    <span>
                      <span
                        data-page-element="ContentEditableNode"
                        className="ne id-6Z-6K9P7D-2188"
                        data-align-selector=".elHeadline"
                        data-slot-name="default"
                      >
                        <span
                          style={{ fontWeight: "normal" }}
                          className="id-6Z-6K9P7D-2322"
                        >
                          <i className="id-6Z-6K9P7D-2323">
                            …So you can know what it’s like to have your
                            reputation backed by undeniable sources that fuel
                            the attention you need to dominate your industry
                            <br className="id-6Z-6K9P7D-2325" />{" "}
                          </i>
                        </span>
                      </span>
                    </span>
                  </h1>
                </div>
                <div
                  data-page-element="SubHeadline/V1"
                  className="elSubheadlineWrapper id-6Z-6K9P7D-2269"
                >
                  <h2
                    className="elSubheadline"
                    data-style-guide-subheadline="s"
                  >
                    <span>
                      <span
                        data-page-element="ContentEditableNode"
                        className="ne id-6Z-6K9P7D-2270"
                        data-align-selector=".elSubheadline"
                        data-slot-name="default"
                      >
                        Click Below to book a call and finally get the attention
                        and recognition ​you need to dominate your industry
                        now...
                      </span>
                    </span>
                  </h2>
                </div>
                <div
                  data-page-element="Button/V1"
                  className="elBTN id-6Z-6K9P7D-2238"
                  data-liquid-replace="item"
                >
                  <a
                    target="_self"
                    href="/contact"
                    className="elButton"
                    aria-label
                    data-disabled="false"
                    data-show-button-ids
                    data-hide-button-ids
                    data-on-submit-go-to
                    data-param-submittingtext="Submitting..."
                    data-style-guide-button="style1"
                    rel="noopener"
                  >
                    <span className="elButtonMain">
                      <i className="fas fa-spinner fa-spin elButtonSpinner elButtonText" />
                      <span className="elButtonMainText elButtonText">
                        YES! Im Ready To Get Featured
                      </span>
                    </span>
                    <span className="elButtonSub" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Heros;
