import React from "react";

import Nav from "../../Templates/Nav";
import Footer from "../../Templates/Footer";



import Why from "../Home/Why";
import Publication from "../Home/Publication";
import Time from "../Home/Time";
import Client from "../Home/Client";
import Testimonials from "../Home/Testimonials";
import Get from "../Home/Get";
import Faqs from "../Home/Faqs";
import Hero from "./Hero";
import Team from "./Team";
function Main() {
  return (
    <>
      <div className="containerWrapper">
        <div className="elPageContentWrapper">
          <div className="elPageContent">
            <div
              data-page-element="ContentNode"
              className="pageRoot id-6Z-6K9P7D-0"
              data-slot-name="default"
            >
              <Nav />
              <Hero />
              <Team />
              <Why />
              <Publication />
              <Time />
              <Client />
              <Testimonials />
              <Get />
              <Faqs />
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Main;
