import React from "react";
import Nav from "../../Templates/Nav";
import Footer from "../../Templates/Footer";
function Privacy() {
  return (
    <>
    <Nav/>
      <div className="team_main">
        <h1 className="world_1">
          Privacy <span className="world_2"> Policy</span>
        </h1>
        <p className="world_p">
          Protection of your privacy is important for us{" "}
        </p>
        <p>
          Welcome to Prestige Perfections Web Agency(the "Site"), owned and
          operated by Prestige Perfections ("we," "us," or "our"). This Privacy
          Policy outlines our practices concerning the collection, use, and
          disclosure of personal information when you visit our website and use
          our services.
        </p>
        <h3>Information We Collect</h3>When you visit our website, we may
        collect personal information that you provide voluntarily. This may
        include, but is not limited to, your name, email address, phone number,
        and any other information you choose to share with us. We may also
        collect certain information automatically when you visit our website.
        This may include your IP address, browser type, device information, and
        other data collected through cookies and similar technologies.
        <h3>How We Use Your Information</h3>We use the collected information for
        the following purposes: Providing Services: To deliver the services you
        request from Prestige Perfections Web Agency. Communication: To respond
        to your inquiries, provide information about our services, and
        communicate with you. Improving Our Website: To analyze website usage
        and enhance our website's functionality and user experience. Marketing:
        To send you updates, newsletters, and promotional materials about our
        services, unless you opt out.<h3>Disclosure of Your Information</h3>We
        do not sell, trade, or otherwise transfer your personal information to
        third parties without your consent, except as described in this Privacy
        Policy or as required by law. We may share your information with trusted
        third-party service providers who assist us in operating our website and
        providing services. These third parties are obligated to maintain the
        confidentiality of your information.<h3>Security</h3>We take reasonable
        measures to protect your personal information from unauthorized access,
        disclosure, alteration, and destruction. However, please be aware that
        no method of transmission over the internet or electronic storage is
        100% secure.<h3>Changes to This Privacy Policy</h3>We may update this
        Privacy Policy from time to time. The latest version will be posted on
        our website, and the date of the last update will be indicated.
        <h3>Contact Us</h3>If you have any questions, concerns, or requests
        regarding this Privacy Policy, please contact us at
        info[at]prestigeperfections.com.
      </div>
      <Footer/>
    </>
  );
}

export default Privacy;
