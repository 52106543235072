// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';

// console.log("JavaScript file loaded successfully");

// // Check if the 'root' element exists and is accessible
// const rootElement = document.getElementById('root');

// if (rootElement) {
//   console.log("Root element found successfully:", rootElement);

//   try {
//     const root = ReactDOM.createRoot(rootElement);
//     console.log("ReactDOM root initialized successfully");

//     root.render(
//       <React.StrictMode>
//         <App />
//       </React.StrictMode>
//     );
//     console.log("App rendered successfully");
    
//   } catch (error) {
//     console.error("Error during rendering:", error);
//   }

// } else {
//   console.error("Root element with id 'root' not found. Possible causes:");
//   console.error("1. The HTML file might not have a <div id='root'></div>.");
//   console.error("2. JavaScript is loading before the HTML structure has loaded.");
//   console.error("3. Check if there’s an issue with bundling or deployment paths.");
// }

// // Optional: Uncomment reportWebVitals if you need it
// // import reportWebVitals from './reportWebVitals';
// // reportWebVitals(console.log);



import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';


console.log("JavaScript file loaded successfully");


const rootElement = document.getElementById('aa');
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
} else {
  console.error("Root element with id 'aa' not found.");
}

// Optional: Only include reportWebVitals if you need it for performance monitoring
// reportWebVitals();