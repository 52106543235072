import React from "react";
import P14 from "../../assest/ad523da93a8ea7b17c77e997d9965492.png";
function Get() {
  return (
    <div
      data-page-element="SectionContainer/V1"
      className="container fullContainer id-6Z-7Gamxz-3"
      data-paint-colors="lightest"
    >
      <div className="containerInnerV2">
        <div
          data-page-element="RowContainer/V1"
          className="row id-6Z-7Gamxz-53"
          data-slot-name="default"
        >
          <div
            data-page-element="ColContainer/V1"
            className="col-md-12 innerContent col_left id-6Z-7Gamxz-54"
          >
            <div className="col-inner">
              <div
                data-page-element="SectionContainer/V1"
                className="container fullContainer id-6Z-7Gamxz-55"
                data-paint-colors="lightest"
              >
                <div className="containerInnerV2">
                  <div
                    data-page-element="RowContainer/V1"
                    className="row id-6Z-7Gamxz-56"
                    data-slot-name="default"
                  >
                    <div
                      data-page-element="ColContainer/V1"
                      className="col-md-4 innerContent col_left id-6Z-7Gamxz-57"
                    >
                      <div className="col-inner">
                        <div
                          data-page-element="Image/V2"
                          className="elImageWrapper de-image-block id-6Z-7Gamxz-82"
                          data-liquid-replace="item"
                          alt="forbesmagazien png"
                        >
                          <img
                            className="elImage"
                            src={P14}
                            width={394}
                            height={441}
                            data-max-width="1000px"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      data-page-element="ColContainer/V1"
                      className="col-md-8 innerContent col_right id-6Z-7Gamxz-58"
                    >
                      <div className="col-inner">
                        <div
                          data-page-element="SubHeadline/V1"
                          className="elSubheadlineWrapper id-6Z-7Gamxz-59"
                        >
                          <h2
                            className="elSubheadline"
                            data-style-guide-subheadline="m"
                          >
                            <span>
                              <span
                                data-page-element="ContentEditableNode"
                                className="ne id-6Z-7Gamxz-60"
                                data-align-selector=".elSubheadline"
                                data-slot-name="default"
                              >
                                GET COVERAGE TODAY
                              </span>
                            </span>
                          </h2>
                        </div>
                        <div
                          data-page-element="Headline/V1"
                          className="elHeadlineWrapper id-6Z-7Gamxz-63"
                        >
                          <h1
                            className="elHeadline"
                            data-style-guide-headline="m"
                          >
                            <span>
                              <span
                                data-page-element="ContentEditableNode"
                                className="ne id-6Z-7Gamxz-64"
                                data-align-selector=".elHeadline"
                                data-slot-name="default"
                              >
                                Will You Be One Of The Few That Let's PR
                                Generate The Attention For You?​
                              </span>
                            </span>
                          </h1>
                        </div>
                        <div
                          data-page-element="SubHeadline/V1"
                          className="elSubheadlineWrapper id-6Z-7Gamxz-73"
                        >
                          <h2
                            className="elSubheadline"
                            data-style-guide-subheadline="m"
                          >
                            <span>
                              <span
                                data-page-element="ContentEditableNode"
                                className="ne id-6Z-7Gamxz-74"
                                data-align-selector=".elSubheadline"
                                data-slot-name="default"
                              >
                                Click Below to finally get the attention and
                                recognition you need to dominate your industry.
                              </span>
                            </span>
                          </h2>
                        </div>
                        <div
                          data-page-element="Button/V1"
                          className="elBTN id-6Z-7Gamxz-80"
                          data-liquid-replace="item"
                        >
                          <a
                            target="_self"
                            href="/contact"
                            className="elButton"
                            aria-label
                            data-disabled="false"
                            data-show-button-ids
                            data-hide-button-ids
                            data-on-submit-go-to
                            data-param-submittingtext="Submitting..."
                            data-style-guide-button="style1"
                            rel="noopener"
                          >
                            <span className="elButtonMain">
                              <i className="fas fa-spinner fa-spin elButtonSpinner elButtonText" />
                              <span className="elButtonMainText elButtonText">
                                YES! Im Ready To Get Featured
                              </span>
                            </span>
                            <span className="elButtonSub" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Get;
