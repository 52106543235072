import React from "react";

function Hero() {
  return (
    <div
      data-page-element="SectionContainer/V1"
      className="container fullContainer bgW100 id-6Z-6K9P7D-2161"
      data-paint-colors="darkest"
    >
      <div className="containerInnerV2">
        <div
          data-page-element="RowContainer/V1"
          className="row id-6Z-6K9P7D-2162"
          data-slot-name="default"
        >
          <div
            data-page-element="ColContainer/V1"
            className="col-md-12 innerContent col_left id-6Z-6K9P7D-2163"
          >
            <div className="col-inner">
              <div
                data-page-element="Headline/V1"
                className="elHeadlineWrapper id-6Z-6K9P7D-2168"
              >
                <h1 className="elHeadline mt-3" data-style-guide-headline="xl">
                  <span>
                    <span
                      data-page-element="ContentEditableNode"
                      className="ne id-6Z-6K9P7D-2169"
                      data-align-selector=".elHeadline"
                      data-slot-name="default"
                    >
                      <span className="id-6Z-6K9P7D-2301">About Us</span>
                    </span>
                  </span>
                </h1>
              </div>
              <div
                data-page-element="Headline/V1"
                className="elHeadlineWrapper id-6Z-6K9P7D-2187"
              >
                <h1 className="elHeadline">
                  <span>
                    <span
                      data-page-element="ContentEditableNode"
                      className="ne id-6Z-6K9P7D-2188"
                      data-align-selector=".elHeadline"
                      data-slot-name="default"
                    >
                      <span
                        style={{ fontWeight: "normal" }}
                        className="id-6Z-6K9P7D-2322"
                      >
                        <i className="id-6Z-6K9P7D-2323">
                          At Prime Authority PR, we specialize in positioning
                          your brand for success.
                          <br className="id-6Z-6K9P7D-2325" />{" "}
                        </i>
                      </span>
                    </span>
                  </span>
                </h1>
              </div>
              <div
                data-page-element="SubHeadline/V1"
                className="elSubheadlineWrapper id-6Z-6K9P7D-2269"
              >
                <h2 className="elSubheadline" data-style-guide-subheadline="s">
                  <span>
                    <span
                      data-page-element="ContentEditableNode"
                      className="ne id-6Z-6K9P7D-2270"
                      data-align-selector=".elSubheadline"
                      data-slot-name="default"
                    >
                      Ready to stand out in a competitive market? Let us lead
                      you to the spotlight! Boost your brand’s visibility, reach
                      new audiences, and solidify your reputation as a leader in
                      your industry.
                    </span>
                  </span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
