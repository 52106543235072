import React from "react";
import "../admin.css"; // Ensure this file includes your footer styling

function AdminFooter() {
  return (
    <footer className="admin-footer">
      <p>© 2024 Prime Authority PR. All rights reserved.</p>
    </footer>
  );
}

export default AdminFooter;
