const publicationsData = [
    {
      name: "Digital Journal",
      url: "https://digitaljournal.com",
      logo: "https://media.licdn.com/dms/image/v2/C560BAQHqpC54rRw19g/company-logo_200_200/company-logo_200_200/0/1631414923930/digital_journal_inc__logo?e=2147483647&v=beta&t=AfwFAtH9GJkMl4jrrjxMm6d4vbs52h2FjnqreKd9evw",
      price: 325,
      da: 87,
      dr: 0,
      tat: "3 Days",
      region: "Global",
      sponsored: "No",
      indexed: "Yes",
      do_follow: "Yes",
      genres: ["Business"],
      tags: ["New"],
      sample_image: "",
      note: "Content is written by a contributor and may not be fully under your control.",
    },
    {
      name: "Clout News",
      url: "https://cloutnews.com",
      logo: "https://pbs.twimg.com/profile_images/1160499296766222337/VZn3kcwb_400x400.jpg",
      price: 349,
      da: 45,
      dr: 46,
      tat: "1 Day",
      region: "United States",
      sponsored: "No",
      indexed: "Yes",
      do_follow: "No",
      genres: ["Lifestyle"],
      tags: [],
      sample_image: "",
      note: "",
    },
    {
      name: "Bloomberg",
      url: "",
      logo: "https://assets.bwbx.io/images/users/iqjWHBFdfxIU/ivUxvlPidC3M/v0/-1x-1.jpg",
      price: 100000,
      da: 94,
      dr: 92,
      tat: "1 Month",
      region: "United States",
      sponsored: "Yes",
      indexed: "Yes",
      do_follow: "Yes",
      genres: ["Business"],
      tags: [],
      sample_image: "",
      note: "",
    },
    {
      name: "Wall Street Journal",
      url: "",
      logo: "https://assets.bwbx.io/images/users/iqjWHBFdfxIU/ivUxvlPidC3M/v0/-1x-1.jpg",
      price: 85000,
      da: 93,
      dr: 92,
      tat: "3-4 Weeks",
      region: "United States",
      sponsored: "Yes",
      indexed: "Yes",
      do_follow: "No",
      genres: ["Business"],
      tags: [],
      sample_image: "",
      note: "500,000 Impressions",
    },
  ];
  
  export default publicationsData;
  