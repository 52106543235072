import React, { useState } from "react";
import NavLogo from "../assest/navlogo.png"

import { Link, useLocation } from "react-router-dom";
function Nav() {
  const [isOpen, setIsOpen] = useState(false);

  // Function to toggle modal visibility
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const location = useLocation();

  // Function to check if the current path is active
  const isActive = (path) => location.pathname === path;
  return (
    <div
      data-page-element="UniversalBlock/14292573"
      className="id-6Z-9JGGmA-62"
    >
      <div className="id-6Z-LalDMW-3">
        <div
          data-page-element="FlexContainer/V1"
          className="elFlexWrap elFlexNoWrapMobile id-6Z-LalDMW-6"
          data-slot-name="default"
        >

          <div
            data-page-element="Image/V2"
            className="elImageWrapper de-image-block id-6Z-LalDMW-7"
            data-liquid-replace="item"
            alt="Black & White Minimalist Aesthetic Initials Font Logo png"
          >
<a href="/">
  <img
    className="elImage"
    src={NavLogo}
    data-element-link="#"
    width={100}
    height={40}
    data-final-width={83}
    data-final-height={77}
    data-max-width="1000px"
  />
</a>

          </div>


          <div
            data-page-element="Navigation/V1"
            data-overlay-menu="mobile"
            className="elNavigator id-6Z-LalDMW-8"
          >
            <nav className="elNavigatorWrapper">
              <div
                className={`elModal id-Modal/V1 ${
                  isOpen ? "modal-open" : "modal-closed"
                }`}
              >
                <div className="elModalInnerContainer">
                  <div onClick={toggleModal} className="elModalClose">
                    <i
                      data-page-element="CloseIcon/V1"
                      className="fas fa-times elClose id-CloseIcon/V1"
                    />
                  </div>
                  <div className="elModalInner">
                    <span className="elNavigatorMobile">
                      <Link to="/">
                        <a
                          data-page-element="LinkNode"
                          className={` ${
                            isActive("/")
                              ? "active-link"
                              : "elTypographyLink id-6Z-LalDMW-11"
                          }`}
                        >
                          <span>HOME</span>
                        </a>
                      </Link>
                      <Link to="/about">
                        <a
                          data-page-element="LinkNode"
                          className={` ${
                            isActive("/about")
                              ? "active-link"
                              : "elTypographyLink id-6Z-LalDMW-11"
                          }`}
                        >
                          <span>ABOUT</span>
                        </a>
                      </Link>
                      <Link to="/contact">
                        <a
                          data-page-element="LinkNode"
                          className={` ${
                            isActive("/contact")
                              ? "active-link"
                              : "elTypographyLink id-6Z-LalDMW-11"
                          }`}
                        >
                          <span>CONTACT</span>
                        </a>
                      </Link>

                      <Link to="/case-study">
                        <a
                          data-page-element="LinkNode"
                          className={` ${
                            isActive("/case-study")
                              ? "active-link"
                              : "elTypographyLink id-6Z-LalDMW-11"
                          }`}
                        >
                          <span>Case studies</span>
                        </a>
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
              <a
                onClick={toggleModal}
                className="elNavigatorButton elTypographyLink"
              >
                <div className="navigatorBtn">
                  <div className="navHamburgerIcon">
                    <div />
                  </div>
                </div>
              </a>
              <span className="elNavigatorDesktop" data-direction="row">
                <Link to="/">
                  <a
                    data-page-element="LinkNode"
                    className={` ${
                      isActive("/")
                        ? "active-link"
                        : "elTypographyLink id-6Z-LalDMW-11"
                    }`}
                  >
                    <span>HOME</span>
                  </a>
                </Link>
                <Link to="/about">
                  <a
                    data-page-element="LinkNode"
                    className={` ${
                      isActive("/about")
                        ? "active-link"
                        : "elTypographyLink id-6Z-LalDMW-11"
                    }`}
                  >
                    <span>ABOUT</span>
                  </a>
                </Link>
                <Link to="/case-study">
                  <a
                    data-page-element="LinkNode"
                    className={` ${
                      isActive("/case-study")
                        ? "active-link"
                        : "elTypographyLink id-6Z-LalDMW-11"
                    }`}
                  >
                    <span>Case studies</span>
                  </a>
                </Link>
                <Link to="/contact">
                  <a
                    data-page-element="LinkNode"
                    className={` ${
                      isActive("/contact")
                        ? "active-link"
                        : "elTypographyLink id-6Z-LalDMW-11"
                    }`}
                  >
                    <span>CONTACT</span>
                  </a>
                </Link>
              </span>
            </nav>
          </div>


          <div
            data-page-element="Button/V1"
            className="elBTN id-6Z-LalDMW-17"
            data-liquid-replace="item"
            data-show-only="desktop"
          >
            <Link to="/contact">
              <a
                target="_self"
                className="elButton elButton_1"
                aria-label
                data-disabled="false"
                data-show-button-ids
                data-hide-button-ids
                data-on-submit-go-to
                data-param-submittingtext="Submitting..."
                data-style-guide-button
                rel="noopener"
              >
                <span className="elButtonMain">
                  <i className="fas fa-spinner fa-spin elButtonSpinner elButtonText" />
                  <span className="elButtonMainText elButtonText elButtonText_1">
                    GET FEATURED
                  </span>
                </span>
                <span className="elButtonSub" />
              </a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Nav;
