import React from "react";

const faqs = [
  {
    question: "Why is press coverage important for my business?",
    answer:
      "Press coverage builds credibility and authority, enhances brand visibility, and attracts potential customers. Featured articles in reputable outlets boost your reputation, provide third-party validation, and establish trust. Additionally, press coverage improves search engine rankings and drives traffic to your website, contributing to business growth and success.",
  },
  {
    question: "Who writes the articles?",
    answer:
      "After placing your order, you will receive a questionnaire to provide details about your business or personal story. Our team of experienced writers will use this information to craft compelling articles that adhere to the highest editorial standards and the specific guidelines of the news publication.",
  },
  {
    question: "Where will my article be published?",
    answer:
      "We collaborate with a wide range of respected news outlets. The publication of your article depends on your specific order and goals. Our team ensures placement in reputable sites that fit your industry and audience.",
  },
  {
    question: "How long does it take to get published?",
    answer:
      "The timeline can vary depending on the publication. Typically, once the questionnaire is completed and the article is written, it takes about 1 - 2 business days for your article to be published.",
  },
  {
    question: "Can I choose the angle of the article?",
    answer:
      "Yes, we encourage you to provide as much detail as possible in the questionnaire. Our writers will incorporate your preferred angle or key messages into the article to ensure it aligns with your goals.",
  },
  {
    question: "Will I be able to review the article before it's published?",
    answer:
      "Yes, we provide you with a draft of the article for review. You’ll have the opportunity to suggest edits or changes before the article is submitted for publication.​",
  },
  {
    question: "Do you guarantee placement in specific publications?",
    answer:
      "While we work closely with many top-tier publications,final editorial decisions are made by the publication itself. We ensure that all submissions meet the standards of reputable outlets, but placements are not guaranteed in any one specific publication.",
  },
  {
    question: "What industries do you work with?",
    answer:
      " We provide press coverage across a variety of industries, including technology, finance, healthcare, lifestyle, entertainment, and more. No matter your niche, we tailor our services to suit your needs.",
  },
];



function Faqs() {
  return (
    <div
      data-page-element="SectionContainer/V1"
      className="container fullContainer"
      data-paint-colors="lightest"
    >
      <div className="containerInnerV2">
        <div
          data-page-element="RowContainer/V1"
          className="row"
          data-slot-name="default"
        >
          <div className="col-md-12 innerContent">
            <div className="col-inner">
              <h2
                className="elSubheadline text-center"
                data-style-guide-subheadline="m"
              >
                STILL GOT QUESTIONS? WE GOT YOU!
              </h2>
              <h1
                className="elHeadline text-center mt3"
                data-style-guide-headline="m"
              >
                Frequently Asked Questions
              </h1>
            </div>
          </div>
        </div>
        <div
          data-page-element="RowContainer/V1"
          className="row myrow"
          data-slot-name="default"
        >
          {faqs.map((faq, index) => (
            <div
              key={index}
              className="col-md-12 innerContent text-center"
              data-page-element="ColContainer/V1"
            >
              <div className="col-inner">
                <h2
                  className="elSubheadline color-black"
                  data-style-guide-subheadline="m"
                >
                  <i className="fa-fw far fa-question-circle color-golden" />
                  {faq.question}
                </h2>
                <p
                  className="elParagraph mt6 text-center"
                  data-style-guide-content="m"
                >
                  {faq.answer}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Faqs;
