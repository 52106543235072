import React from "react";
import P2 from "../../assest/2baaa140a5c7324ffa542714115c556c.png";
import P3 from "../../assest/b26639388d65603c6683cab8da81bdca.png";
import P4 from "../../assest/b8cc9fe59ecb24d7fd528f294d95fcc6.png";
import P5 from "../../assest/338b7d461f9798f818110c5c91e0b904.webp";


import cloutlogo from "../../assest/cloutlogo.webp"


import cloutlogo2 from "../../assest/clout_logo.png"
import businessinsider from "../../assest/business-insider.png"
import binsiderv2 from "../../assest/businessinsiderlogo2.png"



import entrepreneurlogo from "../../assest/entrepreneur-logo-white.png"

const logos = [
  { src: P2, width: 222, height: 60, alt: "forbeslogo png" },
  { src: P3, width: 222, height: 60, alt: "yahoonews png" },
  { src: cloutlogo2, width: 100, height: 10, alt: "forbeslogo png" },
  { src: binsiderv2, width: 222, height: 60, alt: "yahoonews png" },
  // { src: P2, width: 222, height: 60, alt: "forbeslogo png" },
  // { src: P3, width: 222, height: 60, alt: "yahoonews png" },
  // { src: P2, width: 222, height: 60, alt: "forbeslogo png" },
  // { src: P3, width: 222, height: 60, alt: "yahoonews png" },

  // Add more logos as needed
];




function Publication() {
  return (
    <div
      data-page-element="SectionContainer/V1"
      className="container fullContainer id-6Z-6K9P7D-6701"
      data-paint-colors="lightest"
    >
      <div className="containerInnerV2">
        
        <div
          data-page-element="RowContainer/V1"
          className="row id-6Z-6K9P7D-6702"
          data-slot-name="default"
        >
          <div
            data-page-element="ColContainer/V1"
            className="col-md-12 innerContent col_left id-6Z-6K9P7D-6703"
          >
            <div className="col-inner">
              <div
                data-page-element="SubHeadline/V1"
                className="elSubheadlineWrapper id-6Z-6K9P7D-6704"
              >
                <h2 className="elSubheadline" data-style-guide-subheadline="m">
                  <span>
                    <span
                      data-page-element="ContentEditableNode"
                      className="ne id-6Z-6K9P7D-6705"
                      data-align-selector=".elSubheadline"
                      data-slot-name="default"
                    >
                      PUBLICATIONS WE WORK VERY CLOSELY WITH
                    </span>
                  </span>
                </h2>
              </div>
              <div
                data-page-element="Headline/V1"
                className="elHeadlineWrapper id-6Z-6K9P7D-6707"
              >
                <h1 className="elHeadline" data-style-guide-headline="m">
                  <span>
                    <span
                      data-page-element="ContentEditableNode"
                      className="ne id-6Z-6K9P7D-6708"
                      data-align-selector=".elHeadline"
                      data-slot-name="default"
                    >
                      Our Clients Are Consistently Featured In Some Of The Most
                      – Elite Publications
                    </span>
                  </span>
                </h1>
              </div>
            </div>
          </div>
        </div>


        <div className="row id-6Z-6K9P7D-6712" data-slot-name="default">
        {logos.map((logo, index) => (
          <div
            key={index}
            data-page-element="ColContainer/V1"
            className="col-md-3 innerContent"
          >
            <div className="col-inner">
              <div
                data-page-element="Image/V2"
                className="elImageWrapper de-image-block"
                data-liquid-replace="item"
                alt={logo.alt}
              >
                <img
                  className="elImage"
                  src={logo.src}
                  width={logo.width}
                  height={logo.height}
                  data-max-width="1000px"
                  alt={logo.alt}
                />
              </div>
            </div>
          </div>
        ))}
  </div>

        <div
          data-page-element="RowContainer/V1"
          className="row id-6Z-6K9P7D-6745"
          data-slot-name="default"
        >
          <div
            data-page-element="ColContainer/V1"
            className="col-md-12 innerContent col_left id-6Z-6K9P7D-6746"
          >
            <div className="col-inner">
              <div
                data-page-element="SubHeadline/V1"
                className="elSubheadlineWrapper id-6Z-6K9P7D-6747"
              >
                <h2 className="elSubheadline" data-style-guide-subheadline="m">
                  <span>
                    <span
                      data-page-element="ContentEditableNode"
                      className="ne id-6Z-6K9P7D-6748"
                      data-align-selector=".elSubheadline"
                      data-slot-name="default"
                    >
                      ... and plenty more
                    </span>
                  </span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Publication;
