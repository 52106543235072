import React, { useState } from "react";

function Contact() {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
    company: '',
    budget: '',
    service: '',
    details: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://primeauthoritypr.com/backend/send_email.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(formData).toString(),
      });

      const result = await response.json();
      if (response.ok) {
        alert('Your inquiry was successfully submitted. Check your email for confirmation.');
      } else {
        alert(`Failed to submit: ${result.message}`);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div
      data-page-element="SectionContainer/V1"
      className="container fullContainer id-6Z-6K9P7D-6626"
      data-paint-colors="lightest"
    >
      <div className="containerInnerV2">
        <div
          data-page-element="RowContainer/V1"
          className="row id-6Z-6K9P7D-6627 mb-3"
          data-slot-name="default"
        >
          <div
            data-page-element="ColContainer/V1"
            className="col-md-12 innerContent col_left id-6Z-6K9P7D-6628"
          >
            <div className="col-inner">
              <div
                data-page-element="SubHeadline/V1"
                className="elSubheadlineWrapper id-6Z-6K9P7D-6632"
              >
                <h2 className="elSubheadline" data-style-guide-subheadline="m">
                  <span>
                    <span
                      data-page-element="ContentEditableNode"
                      className="ne id-6Z-6K9P7D-6633"
                      data-align-selector=".elSubheadline"
                      data-slot-name="default"
                    >
                      Tell us how we can help
                    </span>
                  </span>
                </h2>
              </div>
              <div
                data-page-element="Headline/V1"
                className="elHeadlineWrapper id-6Z-6K9P7D-6629"
              >
                <h1 className="elHeadline" data-style-guide-headline="m">
                  <span>
                    <span
                      data-page-element="ContentEditableNode"
                      className="ne id-6Z-6K9P7D-6630"
                      data-align-selector=".elHeadline"
                      data-slot-name="default"
                    >
                      Unlock Your Media
                      <br className="id-6Z-LaD9Dq-4" />
                      ​Potential with Us
                    </span>
                  </span>
                </h1>
              </div>
              <div
                data-page-element="Paragraph/V1"
                className="elParagraphWrapper id-6Z-6K9P7D-6643"
              >
                <p className="elParagraph" data-style-guide-content="m">
                  <span>
                    <span
                      data-page-element="ContentEditableNode"
                      className="ne id-6Z-6K9P7D-6644"
                      data-align-selector=".elParagraph"
                      data-slot-name="default"
                    >
                      Prime Authority PR is a public relations and
                      communications agency that specializes in
                      <span className="id-6Z-b4YjXG-65">
                        media placements
                      </span>
                      , personal branding, and marketing strategy. We work
                      with top entrepreneurs, influencers, and established
                      companies to grow their media presence, build strong
                      brand credibility, and boost their business.
                      <br className="id-6Z-b4YjXG-68" />
                    </span>
                  </span>
                </p>
              </div>

              {/* Contact Form */}
              <form onSubmit={handleSubmit}>
                <div
                  data-page-element="RowContainer/V1"
                  className="row id-6Z-LaD9Dq-21 p0"
                  data-slot-name="default"
                >
                  <div
                    data-page-element="ColContainer/V1"
                    className="col-md-6 innerContent col_left id-6Z-LaD9Dq-22"
                  >
                    <div className="col-inner">
                      <div
                        data-page-element="Input/V1"
                        className="elFormItemWrapper elInputWrapper de-input-block id-6Z-LaD9Dq-56 labelInside hasValue mt0"
                        type="name"
                      >
                        <input
                          className="elFormItem elInput inputHolder required1 garlic-auto-save"
                          type="text"
                          name="fullName"
                          placeholder="Full Name"
                          value={formData.fullName}
                          onChange={handleChange}
                          required
                          autoComplete="off"
                        />
                        <div className="elLabel">
                          <div className="borderHolder" />
                          <div className="borderHolder labelText">
                            <label>Full Name</label>
                          </div>
                          <div className="borderHolder" />
                        </div>
                      </div>
                      <div
                        data-page-element="Input/V1"
                        className="elFormItemWrapper elInputWrapper de-input-block id-6Z-LaD9Dq-56 labelInside hasValue relative"
                        type="name"
                      >
                        <input
                          className="elFormItem elInput inputHolder required1 garlic-auto-save plmore"
                          type="number"
                          name="phoneNumber"
                          placeholder="Phone Number"
                          value={formData.phoneNumber}
                          onChange={handleChange}
                          required
                          autoComplete="off"
                        />
                        <div className="elLabel">
                          <div className="borderHolder" />
                          <div className="borderHolder labelText plmore">
                            <label>Phone Number</label>
                          </div>
                          <div className="borderHolder" />
                        </div>
                      </div>
                      <div
                        data-page-element="SelectBox/V1"
                        className="elFormItemWrapper elSelectWrapper de-input-block id-6Z-LaD9Dq-79"
                        data-type="custom_type"
                      >
                        <label className="elSelectLabel">
                          <select
                            className="elFormItem elSelect required1 garlic-auto-save"
                            name="budget"
                            value={formData.budget}
                            onChange={handleChange}
                            required
                          >
                            <option value="">What's Your Estimated Budget</option>
                            <option value="$1,000 - $5,000">$1,000 - $5,000</option>
                            <option value="$5,001 - $10,000">$5,001 - $10,000</option>
                            <option value="$10,001 - $20,000">$10,001 - $20,000</option>
                            <option value="$20,001 - $30,000">$20,001 - $30,000</option>
                            <option value="$30,001 - $100,000">$30,001 - $100,000</option>
                          </select>
                          <div className="elSelectArrow">
                            <i className="fas fa-chevron-down" />
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div
                    data-page-element="ColContainer/V1"
                    className="col-md-6 innerContent col_right id-6Z-LaD9Dq-23 mt0"
                  >
                    <div className="col-inner">
                      <div
                        data-page-element="Input/V1"
                        className="elFormItemWrapper elInputWrapper de-input-block id-6Z-LaD9Dq-56 labelInside hasValue mt0"
                        type="name"
                      >
                        <input
                          className="elFormItem elInput inputHolder required1 garlic-auto-save"
                          type="email"
                          name="email"
                          placeholder="Email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                          autoComplete="off"
                        />
                        <div className="elLabel">
                          <div className="borderHolder" />
                          <div className="borderHolder labelText">
                            <label>Email</label>
                          </div>
                          <div className="borderHolder" />
                        </div>
                      </div>
                      <div
                        data-page-element="Input/V1"
                        className="elFormItemWrapper elInputWrapper de-input-block id-6Z-LaD9Dq-56 labelInside hasValue"
                        type="name"
                      >
                        <input
                          className="elFormItem elInput inputHolder required1 garlic-auto-save"
                          type="text"
                          name="company"
                          placeholder="Your Company / Organisation (optional)"
                          value={formData.company}
                          onChange={handleChange}
                          autoComplete="off"
                        />
                        <div className="elLabel">
                          <div className="borderHolder" />
                          <div className="borderHolder labelText">
                            <label>Your Company / Organisation (optional)</label>
                          </div>
                          <div className="borderHolder" />
                        </div>
                      </div>
                      <div
                        data-page-element="SelectBox/V1"
                        className="elFormItemWrapper elSelectWrapper de-input-block id-6Z-LaD9Dq-118"
                        data-type="custom_type"
                      >
                        <label className="elSelectLabel">
                          <select
                            className="elFormItem elSelect required1 garlic-auto-save"
                            name="service"
                            value={formData.service}
                            onChange={handleChange}
                            required
                          >
                            <option value="">Which service are you interested in</option>
                            <option value="Media Placement">Media Placement</option>
                            {/* <option value="Branding">Branding</option>
                            <option value="Marketing Strategy">Marketing Strategy</option> */}
                          </select>
                          <div className="elSelectArrow">
                            <i className="fas fa-chevron-down" />
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-page-element="RowContainer/V1"
                  className="row id-6Z-LaD9Dq-57 pt0 p0 mt-3"
                  data-slot-name="default"
                >
                  <div
                    data-page-element="ColContainer/V1"
                    className="col-md-12 innerContent col_left id-6Z-LaD9Dq-58"
                  >
                    <div className="col-inner">
                      <div
                        data-page-element="TextArea/V1"
                        className="elFormItemWrapper elInputWrapper de-input-block id-6Z-LaD9Dq-56 labelInside hasValue mt0"
                        type="custom_type"
                      >
                        <textarea
                          className="elFormItem elTextarea inputHolder elInput required1 garlic-auto-save hasValue"
                          name="details"
                          placeholder="Tell us a bit more about you or your organisation"
                          value={formData.details}
                          onChange={handleChange}
                          required
                        />
                        <div className="elLabel">
                          <div className="borderHolder" />
                          <div className="borderHolder labelText labelText_1">
                            <label>Tell us a bit more about you or your organisation</label>
                          </div>
                          <div className="borderHolder" />
                        </div>
                      </div>
                      <div
                        data-page-element="Button/V1"
                        className="elBTN id-6Z-LaD9Dq-60 w-full"
                      >
                        <button
                          type="submit"
                          className="elButton w-full"
                        >
                          <span className="elButtonMain">
                            <i className="fas fa-spinner fa-spin elButtonSpinner elButtonText" />
                            <span className="elButtonMainText elButtonText">Submit</span>
                          </span>
                          <span className="elButtonSub" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              {/* End of Contact Form */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
