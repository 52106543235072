import React from "react";

function Why() {
  return (
    <div
      data-page-element="SectionContainer/V1"
      className="container fullContainer id-6Z-6K9P7D-6626"
      data-paint-colors="lightest"
    >
      <div className="containerInnerV2">
        <div
          data-page-element="RowContainer/V1"
          className="row id-6Z-6K9P7D-6627"
          data-slot-name="default"
        >
          <div
            data-page-element="ColContainer/V1"
            className="col-md-12 innerContent col_left id-6Z-6K9P7D-6628"
          >
            <div className="col-inner">
              <div
                data-page-element="SubHeadline/V1"
                className="elSubheadlineWrapper id-6Z-6K9P7D-6632"
              >
                <h2 className="elSubheadline" data-style-guide-subheadline="m">
                  <span>
                    <span
                      data-page-element="ContentEditableNode"
                      className="ne id-6Z-6K9P7D-6633"
                      data-align-selector=".elSubheadline"
                      data-slot-name="default"
                    >
                      WHY Prime Autority PR?
                    </span>
                  </span>
                </h2>
              </div>
              <div
                data-page-element="Headline/V1"
                className="elHeadlineWrapper id-6Z-6K9P7D-6629"
              >
                <h1 className="elHeadline" data-style-guide-headline="m">
                  <span>
                    <span
                      data-page-element="ContentEditableNode"
                      className="ne id-6Z-6K9P7D-6630"
                      data-align-selector=".elHeadline"
                      data-slot-name="default"
                    >
                      What Makes us Different
                    </span>
                  </span>
                </h1>
              </div>
              <div
                data-page-element="Paragraph/V1"
                className="elParagraphWrapper id-6Z-6K9P7D-6643"
              >
                <p className="elParagraph" data-style-guide-content="m">
                  <span>
                    <span
                      data-page-element="ContentEditableNode"
                      className="ne id-6Z-6K9P7D-6644"
                      data-align-selector=".elParagraph"
                      data-slot-name="default"
                    >
                      Prime Authority PR is a public relations and
                      communications agency that specializes in media placements, personal branding, and marketing strategy. We work with
                      top entrepreneurs, influencers, and established companies to grow their media presence, build strong brand credibility, and boost their business.
                   
                    </span>
                  </span>
                </p>
              </div>
              <div
                data-page-element="SectionContainer/V1"
                className="container fullContainer id-6Z-6K9P7D-6662"
                data-paint-colors="lightest"
              >
                <div className="containerInnerV2">
                  <div
                    data-page-element="RowContainer/V1"
                    className="row id-6Z-6K9P7D-6787 flexwrap"
                    data-slot-name="default"
                  >
                    <div
                      data-page-element="ColContainer/V1"
                      className="col-md-3 innerContent col_left id-6Z-6K9P7D-6788"
                    >
                      <div className="col-inner">
                        <div
                          data-page-element="Icon/V1"
                          data-liquid-replace="true"
                          className="id-6Z-6K9P7D-6693"
                        >
                          <a
                            className="iconElement"
                            href
                            target="_self"
                            data-show-button-ids
                            data-hide-button-ids
                            data-on-submit-go-to
                          >
                            <i
                              data-page-element="IconNode"
                              className="fa-fw fa_icon id-IconNode fas fa-user"
                            />
                          </a>
                        </div>
                        <div
                          data-page-element="Headline/V1"
                          className="elHeadlineWrapper id-6Z-6K9P7D-6668"
                        >
                          <h1
                            className="elHeadline"
                            data-style-guide-headline="m"
                          >
                            <span>
                              <span
                                data-page-element="ContentEditableNode"
                                className="ne id-6Z-6K9P7D-6669"
                                data-align-selector=".elHeadline"
                                data-slot-name="default"
                              >
                                100+
                              </span>
                            </span>
                          </h1>
                        </div>
                        <div
                          data-page-element="SubHeadline/V1"
                          className="elSubheadlineWrapper id-6Z-6K9P7D-6678"
                        >
                          <h2
                            className="elSubheadline"
                            data-style-guide-subheadline="m"
                          >
                            <span>
                              <span
                                data-page-element="ContentEditableNode"
                                className="ne id-6Z-6K9P7D-6679"
                                data-align-selector=".elSubheadline"
                                data-slot-name="default"
                              >
                                Happy Clients
                              </span>
                            </span>
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div
                      data-page-element="ColContainer/V1"
                      className="col-md-3 innerContent col_right id-6Z-6K9P7D-6789 mt0"
                    >
                      <div className="col-inner">
                        <div
                          data-page-element="Icon/V1"
                          data-liquid-replace="true"
                          className="id-6Z-6K9P7D-6694"
                        >
                          <a
                            className="iconElement"
                            href
                            target="_self"
                            data-show-button-ids
                            data-hide-button-ids
                            data-on-submit-go-to
                          >
                            <i
                              data-page-element="IconNode"
                              className="fa-fw fa_icon id-IconNode far fa-newspaper"
                            />
                          </a>
                        </div>
                        <div
                          data-page-element="Headline/V1"
                          className="elHeadlineWrapper id-6Z-6K9P7D-6793"
                        >
                          <h1
                            className="elHeadline"
                            data-style-guide-headline="m"
                          >
                            <span>
                              <span
                                data-page-element="ContentEditableNode"
                                className="ne id-6Z-6K9P7D-6794"
                                data-align-selector=".elHeadline"
                                data-slot-name="default"
                              >
                                1000+
                              </span>
                            </span>
                          </h1>
                        </div>
                        <div
                          data-page-element="SubHeadline/V1"
                          className="elSubheadlineWrapper id-6Z-6K9P7D-6686"
                        >
                          <h2
                            className="elSubheadline"
                            data-style-guide-subheadline="m"
                          >
                            <span>
                              <span
                                data-page-element="ContentEditableNode"
                                className="ne id-6Z-6K9P7D-6687"
                                data-align-selector=".elSubheadline"
                                data-slot-name="default"
                              >
                                Media Outlets
                              </span>
                            </span>
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div
                      data-page-element="ColContainer/V1"
                      className="col-md-3 innerContent col_right id-6Z-6K9P7D-6790"
                    >
                      <div className="col-inner">
                        <div
                          data-page-element="Icon/V1"
                          data-liquid-replace="true"
                          className="id-6Z-6K9P7D-6792"
                        >
                          <a
                            className="iconElement"
                            href
                            target="_self"
                            data-show-button-ids
                            data-hide-button-ids
                            data-on-submit-go-to
                          >
                            <i
                              data-page-element="IconNode"
                              className="fa-fw fa_icon id-IconNode fas fa-desktop"
                            />
                          </a>
                        </div>
                        <div
                          data-page-element="Headline/V1"
                          className="elHeadlineWrapper id-6Z-6K9P7D-6672"
                        >
                          <h1
                            className="elHeadline"
                            data-style-guide-headline="m"
                          >
                            <span>
                              <span
                                data-page-element="ContentEditableNode"
                                className="ne id-6Z-6K9P7D-6673"
                                data-align-selector=".elHeadline"
                                data-slot-name="default"
                              >
                              500+
                              </span>
                            </span>
                          </h1>
                        </div>
                        <div
                          data-page-element="SubHeadline/V1"
                          className="elSubheadlineWrapper id-6Z-6K9P7D-6796"
                        >
                          <h2
                            className="elSubheadline"
                            data-style-guide-subheadline="m"
                          >
                            <span>
                              <span
                                data-page-element="ContentEditableNode"
                                className="ne id-6Z-6K9P7D-6797"
                                data-align-selector=".elSubheadline"
                                data-slot-name="default"
                              >
                                Articles Published
                              </span>
                            </span>
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div
                      data-page-element="ColContainer/V1"
                      className="col-md-3 innerContent col_right id-6Z-6K9P7D-6791"
                    >
                      <div className="col-inner">
                        <div
                          data-page-element="Icon/V1"
                          data-liquid-replace="true"
                          className="id-6Z-6K9P7D-6692"
                        >
                          <a
                            className="iconElement"
                            href
                            target="_self"
                            data-show-button-ids
                            data-hide-button-ids
                            data-on-submit-go-to
                          >
                            <i
                              data-page-element="IconNode"
                              className="fa-fw fa_icon id-IconNode fas fa-award"
                            />
                          </a>
                        </div>
                        <div
                          data-page-element="Headline/V1"
                          className="elHeadlineWrapper id-6Z-6K9P7D-6675"
                        >
                          <h1
                            className="elHeadline"
                            data-style-guide-headline="m"
                          >
                            <span>
                              <span
                                data-page-element="ContentEditableNode"
                                className="ne id-6Z-6K9P7D-6676"
                                data-align-selector=".elHeadline"
                                data-slot-name="default"
                              >
                                100%
                              </span>
                            </span>
                          </h1>
                        </div>
                        <div
                          data-page-element="SubHeadline/V1"
                          className="elSubheadlineWrapper id-6Z-6K9P7D-6689"
                        >
                          <h2
                            className="elSubheadline"
                            data-style-guide-subheadline="m"
                          >
                            <span>
                              <span
                                data-page-element="ContentEditableNode"
                                className="ne id-6Z-6K9P7D-6690"
                                data-align-selector=".elSubheadline"
                                data-slot-name="default"
                              >
                                Satisfaction guaranteed
                                <br className="id-6Z-6K9P7D-6699" />​
                              </span>
                            </span>
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Why;
