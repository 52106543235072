import React from "react";

import P8 from "../../assest/030e5ea8d38e310832c4b4362abe5212.jpg";
import P9 from "../../assest/9760db805d5baa2921c7de1e8e926095.jpeg";
import P10 from "../../assest/dd5a967dec10da728e0780f2025166c0.jpeg";
import P11 from "../../assest/f9707a2ae9ff533b7a8102025ffa0aae.png";
import P12 from "../../assest/37f9c37a5b5cd6c644e9ff10764a5b6a.jpeg";

const testimonials = [
  {
    image: P8,
    name: "Sean Borg",
    quote: `"Over the years, Saqib has worked with me on everything from press, reputation management, brand design, online and print. Projects are always approached with enthusiasm, care and a focus to deliver on-time and with the agreed budget."`,
  },
  {
    image: P9,
    name: "Mo Abdul",
    quote: `"Saqib Malik has provided us with a comprehensive, fast and well-planned digital marketing strategy that has yielded great results in terms of content, SEO, press or social media. He and his team are a pleasure to work with, fast to respond and adapt to the needs of your brand."`,
  },
  {
    image: P10,
    name: "Rishi Jain",
    quote: `"Saqib Malik's company is the most professional social media management company. They fulfilled my expectations as a client and delivered their services in a timely and professional manner. I would definitely recommend them to my colleagues."`,
  },
  // {
  //   image: P11,
  //   name: "Bhavya Munjal",
  //   quote: `"Great work carried out by the team. Really satisfied with the complete business branding and PR work. Would highly recommend."`,
  // },
  // {
  //   image: P12,
  //   name: "Omar Zahran",
  //   quote: `"Saqib's team was instrumental in shaping our digital presence. Their professionalism and timely delivery exceeded our expectations."`,
  // },
  // {
  //   image: P11,
  //   name: "Bhavya Munjal",
  //   quote: `"Great work carried out by the team. Really satisfied with the complete business branding and PR work. Would highly recommend."`,
  // },
];

function Testimonials() {
  return (
    <div data-page-element="SectionContainer/V1" className="container fullContainer" data-paint-colors="lightest">
      <div className="containerInnerV2">
        <div data-page-element="RowContainer/V1" className="row" data-slot-name="default" >
         
          <div className="col-md-12 innerContent">

            <div className="col-inner">

              <h2 className="elSubheadline text-center"data-style-guide-subheadline="m" >
                CUSTOMER TESTIMONIALS
              </h2>
              
              <h1 className="elHeadline mt3 text-center" data-style-guide-headline="m">
                What Our Past Clients Have To Say About Us! 
              </h1>

              <div className="row myrow1 mt6">
                {testimonials.map((testimonial, index) => (
                  <div className="col-md-12 innerContent" key={index}>
                    <div className="col-inner">
                      <div className="elImageWrapper">
                        <img
                          className="elImage rounded"
                          src={testimonial.image}
                          alt={testimonial.name}
                          width="100%"
                          height="auto"
                        />
                      </div>
                      <p className="elParagraph text-center">
                        {testimonial.quote}
                      </p>
                      <h2 className="elSubheadline text-center color-black">
                        - {testimonial.name}
                      </h2>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
