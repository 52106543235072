import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../src/assest/Prime Authority Logo v2.png";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(""); // Clear any existing errors

    try {
      const response = await fetch("https://primeauthoritypr.com/backend/api/authenticate/login.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (data.token) {
        // Store JWT token in localStorage
        localStorage.setItem("token", data.token);

        // Redirect based on user role
        if (data.redirect === "/admin") {
          navigate("/admin");
        } else {
          navigate("/user");
        }
      } else if (data.error) {
        setError(data.error);
      }
    } catch (err) {
      console.error("Login failed:", err);
      setError("An error occurred. Please try again later.");
    }
  };

  return (
    <div className="boost">
      <div className="boost_1">
        <img src={Logo} alt="Logo" className="logo" />
      </div>
      <div className="boost_3">
        <h1 className="boost_8">Hello Again!</h1>
        <p className="boost_9">Welcome Back</p>
        {error && <p className="error-message">{error}</p>}
        <form className="boost_11" onSubmit={handleLogin}>
          <p className="boost_12">Enter your Email Address</p>
          <div className="boost_13">
            <input
              type="text"
              placeholder="Email Address"
              className="boost_14"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <br />
          <p className="boost_12">Enter your Password</p>
          <div className="boost_13">
            <input
              type="password"
              placeholder="Password"
              className="boost_14"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="r_me">
            <input type="checkbox" />
            <p>Remember me</p>
          </div>
          <button type="submit" className="loginbtn">Login</button>
        </form>
        <p className="boost_8 fp" onClick={() => navigate("/forgot-password")}>
          Forgot Password?
        </p>
        <p className="register">
          Don’t have an account?{" "}
          <span className="now" onClick={() => navigate("/register")}>
            Register
          </span>
        </p>
      </div>
    </div>
  );
}

export default Login;
