import React from "react";
import { useNavigate, Link } from "react-router-dom";
import { logout } from "../../auth";


import Logo from "../../assest/Prime Authority Logo v2.png"

import "../UserArea.css";

function UserHeader() {
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  return (
    <header className="admin-header">
      {/* Left Side - Logo */}
      <div className="header-logo">
        <Link to="/user/">
          <img src={Logo} alt="Admin Logo" className="admin-logo" />
        </Link>
      </div>

      {/* Center - Navigation Menu */}
      <nav className="header-nav">
        <Link to="/user/orders" className="nav-link">
          Orders
        </Link>
        {/* <Link to="/admin/orders" className="nav-link">
          Orders
        </Link> */}
        {/* {/* <Link to="/case-study" className="nav-link">
          CASE STUDIES
        </Link> */}
        {/* <Link to="/admin/transactions" className="nav-link">
          Transactions
        </Link>  */}
      </nav>

      {/* Right Side - Logout */}
      <div className="header-actions">
        <button onClick={handleLogout} className="logout-btn">
          Logout
        </button>
      </div>
    </header>
  );
}

export default UserHeader;
