import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Template/Header";
import AdminFooter from "./Template/Footer";
import "./admin.css"; // Ensure CSS for styling

function Orders() {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [filter, setFilter] = useState("pending"); // Default filter set to "pending"
  const [searchTerm, setSearchTerm] = useState("");

  const navigate = useNavigate();

  // Fetch orders from the backend API
  useEffect(() => {
    async function fetchOrders() {
      const response = await fetch("https://primeauthoritypr.com/backend/api/admin/get_orders.php");
      const data = await response.json();
      setOrders(data);
      setFilteredOrders(data.filter(order => order.status === "pending")); // Default to pending
    }
    fetchOrders();
  }, []);

  // Handle click to navigate to order details
  const handleOrderClick = (orderId) => {
    navigate(`/admin/orders/${orderId}`);
  };

  // Handle order deletion
  const handleDeleteOrder = async (orderId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this order?");
    if (!confirmDelete) return;

    const response = await fetch(`https://primeauthoritypr.com/backend/api/admin/delete_order.php`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ order_id: orderId }),
    });

    if (response.ok) {
      setOrders((prevOrders) => prevOrders.filter((order) => order.order_id !== orderId));
      setFilteredOrders((prevOrders) => prevOrders.filter((order) => order.order_id !== orderId));
      alert("Order and associated transaction deleted successfully");
    } else {
      alert("Failed to delete order and transaction");
    }
  };

  // Filter and Search Functions
  const handleFilterChange = (status) => {
    setFilter(status);
    if (status === "All") {
      setFilteredOrders(orders);
    } else {
      setFilteredOrders(orders.filter(order => order.status === status));
    }
  };

  const handleSearch = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);
    
    const searchResults = orders.filter(order => 
      order.order_id.toString().includes(searchValue) || 
      `${order.first_name} ${order.last_name}`.toLowerCase().includes(searchValue)
    );
    setFilteredOrders(searchResults);
  };

  return (
    <>
      <Header />
      <div className="orders">
        <div className="filter-search-container">
          {/* Filter Tabs */}
          <div className="filter-tabs">
            <button className={filter === "All" ? "active" : ""} onClick={() => handleFilterChange("All")}>
              All
            </button>
            <button className={filter === "fulfilled" ? "active" : ""} onClick={() => handleFilterChange("fulfilled")}>
              Fulfilled
            </button>
            <button className={filter === "pending" ? "active" : ""} onClick={() => handleFilterChange("pending")}>
              Pending
            </button>
          </div>
          
          {/* Search Bar */}
          <input
            type="text"
            placeholder="Search by Order ID or Name"
            value={searchTerm}
            onChange={handleSearch}
            className="search-input"
          />
        </div>

        <table className="orders-table">
          <thead>
            <tr>
              <th>Order ID</th>
              <th>User Name</th>
              <th>Product Name</th>
              <th>Price</th>
              <th>Status</th>
              <th>Created At</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredOrders.map((order) => (
              <tr key={order.order_id} onClick={() => handleOrderClick(order.order_id)}>
                <td>{order.order_id}</td>
                <td>{`${order.first_name} ${order.last_name}`}</td>
                <td>{order.product_name}</td>
                <td>${Number(order.price).toFixed(2)}</td>
                <td>{order.status}</td>
                <td>{order.created_at}</td>
                <td>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteOrder(order.order_id);
                    }}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <AdminFooter />
    </>
  );
}

export default Orders;
