import React from "react";

function Time() {
  const bulletPoints = [
    "Use PR to Create Undeniable Social Proof in Sales.",
    "Most importantly, you will learn exactly how industry leaders like Gary Vaynerchuk, Kylie Jenner, and Grant Cardone use PR as their best-kept secret. You'll also learn how you can 'skip the line' so you can immediately begin capitalizing on the advantage press coverage has the power to provide.",
    "Use “Press Coverage” To Skyrocket Profitability of Marketing Campaigns.",
    "If you’ve ever felt like your marketing efforts are leaking profits, then PR may be your best, and quickest bet at plugging that leak for good, preventing your ads and content marketing from wasting any more time and money.",
    "Create A “Legacy That Lasts” In Weeks Rather Than Decades.",
    "Discover the exact steps that have helped 1,000 businesses and personal brands build a legacy that lasts by securing features inside major media outlets like Forbes, Entrepreneur, Fox News, The Huffington Post, Bloomberg, Business Insider, International Business Times, and Nasdaq, etc. (to name a few). This can be achieved without the wait, so you can start dominating your niche before your competition knows what hit them."
  ];

  return (
    <div
      data-page-element="SectionContainer/V1"
      className="container fullContainer id-6Z-6K9P7D-6770"
      data-paint-colors="lightest"
    >
      <div className="containerInnerV2">
        <div
          data-page-element="RowContainer/V1"
          className="row id-6Z-6K9P7D-6771"
          data-slot-name="default"
        >
          <div
            data-page-element="ColContainer/V1"
            className="col-md-12 innerContent col_left id-6Z-6K9P7D-6772"
          >
            <div className="col-inner">
              <div
                data-page-element="SubHeadline/V1"
                className="elSubheadlineWrapper id-6Z-6K9P7D-6773"
              >
                <h2 className="elSubheadline" data-style-guide-subheadline="m">
                  <span>
                    <span
                      data-page-element="ContentEditableNode"
                      className="ne id-6Z-6K9P7D-6774"
                      data-align-selector=".elSubheadline"
                      data-slot-name="default"
                    >
                      WHY Prime Authority PR?
                    </span>
                  </span>
                </h2>
              </div>
              <div
                data-page-element="Headline/V1"
                className="elHeadlineWrapper id-6Z-6K9P7D-6776"
              >
                <h1 className="elHeadline" data-style-guide-headline="m">
                  <span>
                    <span
                      data-page-element="ContentEditableNode"
                      className="ne id-6Z-6K9P7D-6777"
                      data-align-selector=".elHeadline"
                      data-slot-name="default"
                    >
                      Our Time Together
                      <br className="id-6Z-6K9P7D-6783" />
                      ​Will Reveal How You Can:
                    </span>
                  </span>
                </h1>
              </div>
              <div
                data-page-element="BulletList/V1"
                className="elBullet id-6Z-6K9P7D-6805"
              >
                <ul
                  className="ne elBulletList elBulletLisNew elBulletList2"
                  data-style-guide-content="m"
                >
                  {bulletPoints.map((point, index) => (
                    <li key={index} className="id-6Z-6K9P7D-7035">
                      <i
                        data-page-element="IconNode"
                        className="fa-fw fa_icon fa_icon fa_icon fa fa-check id-6Z-6K9P7D-7036"
                        contentEditable="false"
                      />
                      <span className="elBulletListTextWrapper id-6Z-6K9P7D-7037">
                        <span className="id-6Z-6K9P7D-7038">
                          {point}
                        </span>
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Time;
