import React, { useState, useEffect } from "react";
import Logov2 from "../assest/Prime Authority Logo v2.png"

const info = [
  { name: "Prime Authority PR" },
  { copyright: "Copyright © 2024  Prime Authority PR. All rights reserved." },
  { disclaimer: "  Disclaimer: Meta, Facebook, Instagram, TikTok, and LinkedIn are registered trademarks of their respective owners. Prime Authority PR is not affiliated with or endorsed by Meta, Facebook, Instagram, TikTok, or LinkedIn. All product names, logos, and brands are property of their respective owners. Use of these names, logos, and brands does not imply endorsement. Prime Authority PR is a public relations agency specializing in premium media placements and reputation management; we do not offer business opportunities or financial promises. " },
];


const quickLinks = [
  { name: "Home", href: "/" },
  { name: "About", href: "/about" },
  { name: "Case Studies", href: "/case-study" },
  { name: "Faqs", href: "/faqs" },
];

const socials = [
  {
    social_name: "Instagram",
    href: "https://www.instagram.com/primeauthoritypr",
    target: "_blank",
  },
  {
    social_name: "TikTok",
    href: "https://www.tiktok.com/@primeauthoritypr",
    target: "_blank",
  },
  {
    social_name: "Facebook",
    href: "https://www.facebook.com/primeauthoritypr",
    target: "_self",
  },
  {
    social_name: "LinkedIn",
    href: "https://www.linkedin.com/company/primeauthoritypr",
    target: "_self",
  },
];
const contacts = [
  { name: "Contact", href: "/contact" },
  // { name: "Pricing", href: "/pricelist",},
  { name: "Privacy", href: "/privacy",},
  { name: "Terms & Conditions", href: "/terms",},
];

function Footer() {
  const [showScrollButton, setShowScrollButton] = useState(false);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    if (
      scrollY > windowHeight / 2 ||
      scrollY + windowHeight >= documentHeight
    ) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div
      data-page-element="SectionContainer/V1"
      className="container fullContainer id-6Z-6K9P7D-8130"
      data-paint-colors="lightest"
    >
      <div className="containerInnerV2">
        <div
          data-page-element="RowContainer/V1"
          className="row id-6Z-6K9P7D-8131"
          data-slot-name="default"
        >
          <div
            data-page-element="ColContainer/V1"
            className="col-md-3 innerContent col_left id-6Z-6K9P7D-8132"
          >
            <div className="col-inner">
              <div
                data-page-element="Image/V2"
                className="elImageWrapper de-image-block id-6Z-6K9P7D-8136"
                alt="Black & White Minimalist Aesthetic Initials Font Logo png"
              >
                <a href="/">
                  <img
                    className="elImage"
                    src={Logov2}
                    width={500}
                    height={500}
                    data-max-width="1000px"
                    alt="Footer Logo"
                  />
                </a>
                {/* <div className="mt-3 mt3 socials_icons flex-icons">
                  {socials.map((link, index) => (
                    <a
                      key={index}
                      target={link.target}
                      className="elLink elTypographyLink color-white mx0"
                      href={link.href}
                    >
                      <i
                        className={`color-white fab fa-${link.social_name.toLowerCase()}`}
                      ></i>
                    </a>
                  ))}
                </div> */}
                <div className="flexwrapreverse">
                {/* <p className="footer-text mt0">
                  Prime Authority PR specializes in media placements, branding,
                  and marketing to elevate entrepreneurs, influencers, and
                  businesses.
                </p> */}
                <div className="mt-3 mt3 socials_icons flex-icons">
                  {socials.map((link, index) => (
                    <a
                      key={index}
                      target={link.target}
                      className="elLink elTypographyLink color-white mx0"
                      href={link.href}
                    >
                      <i
                        className={`color-white fab fa-${link.social_name.toLowerCase()}`}
                      ></i>
                    </a>
                  ))}
                </div>
                </div>
              </div>
            </div>
            {/* <div
              data-page-element="Headline/V1"
              className="elHeadlineWrapper id-6Z-6K9P7D-8140 text-lefts"
            >
              <h1 className="elHeadline text-lefts" data-style-guide-headline="s">
                <span className="text-lefts">Socials</span>
              </h1>
            </div> */}
          </div>
          <div
            data-page-element="ColContainer/V1"
            className="col-md-3 innerContent col_right id-6Z-6K9P7D-8133"
          >
            <div className="col-inner">

              {/* <div
                data-page-element="Headline/V1"
                className="elHeadlineWrapper id-6Z-6K9P7D-8137 mt3"
              >
                <h1 className="elHeadline" data-style-guide-headline="s">
                  <span>Quick Links</span>
                </h1>
              </div> */}


              {quickLinks.map((link, index) => (
                <div
                  key={index}
                  data-page-element="Link/V1"
                  className="elLinkWrapper pb10"
                >
                  <a
                    target="_self"
                    className="elLink elTypographyLink color-white"
                    href={link.href}
                  >
                    {link.name}
                  </a>
                </div>
              ))}
            </div>
          </div>

          <div
            data-page-element="ColContainer/V1"
            className="col-md-3 innerContent col_right id-6Z-6K9P7D-8134"
          >
            <div className="col-inner">


              {/* <div
                data-page-element="Headline/V1"
                className="elHeadlineWrapper id-6Z-6K9P7D-8140"
              >
                <h1 className="elHeadline " data-style-guide-headline="s">
                  <span>Contact</span>
                </h1>
              </div> */}


              {contacts.map((social, index) => (
                <div
                  key={index}
                  data-page-element="Link/V1"
                  className="elLinkWrapper pb10"
                >
                  <a
                    target={social.target}
                    className="elLink elTypographyLink color-white"
                    href={social.href}
                  >
                    {social.name}
                  </a>
                </div>
              ))}
            </div>
          </div>

          <div
            data-page-element="ColContainer/V1"
            className="col-md-3 innerContent col_right id-6Z-6K9P7D-8135"
          >
            <div className="col-inner">
              <div
                data-page-element="Headline/V1"
                className="elHeadlineWrapper id-6Z-6K9P7D-8151"
              >
                <h3 className="elHeadline" data-style-guide-headline="s">
                  <span>Let's Scale Your Business</span>
                </h3>
              </div>
              <div
                data-page-element="Button/V1"
                className="elBTN id-6Z-6K9P7D-8191"
              >
                <a
                  target="_self"
                  href="/contact"
                  className="elButton"
                  aria-label
                  data-disabled="false"
                  rel="noopener"
                >
                  <span className="elButtonMain">
                    <i className="fas fa-spinner fa-spin elButtonSpinner elButtonText" />
                    <span className="elButtonMainText elButtonText">
                      GET FEATURED
                    </span>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div
          data-page-element="RowContainer/V1"
          className="row id-6Z-6K9P7D-8220"
          data-slot-name="default"
        >
          <div
            data-page-element="ColContainer/V1"
            className="col-md-12 innerContent col_left id-6Z-6K9P7D-8221"
          >
            <div className="col-inner">
              <div
                data-page-element="SubHeadline/V1"
                className="elSubheadlineWrapper id-6Z-6K9P7D-8222"
              >
                <h2 className="elSubheadline" data-style-guide-subheadline="m">
                  <span>{info[1].copyright}
                  </span>
                </h2>
              </div>
              <div
                data-page-element="Paragraph/V1"
                className="elParagraphWrapper id-6Z-ZW7kAo-3"
              >
                <p className="elParagraph">
                  <span> {info[2].disclaimer} 

                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        
        {showScrollButton && (
          <button
            className="back-to-top"
            onClick={scrollToTop}
            style={{
              position: "fixed",
              bottom: "20px",
              right: "20px",
              backgroundColor: "#d4af37",
              color: "white",
              border: "none",
              borderRadius: "5px",
              padding: "10px 15px",
              cursor: "pointer",
              boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
            }}
          >
            ↑
          </button>
        )}
      </div>
    </div>
  );
}

export default Footer;
