import React from "react";
import Logo from "../../src/assest/Prime Authority Logo v2.png";

function ForgotPassword() {
  return (
    <>
      <div className="boost">
        <div className="boost_1">
          <img src={Logo} alt="Logo" className="logo" />
        </div>
        <div className="boost_3">
          <h1 className="boost_8">Reset Password</h1>
          <p className="boost_9">Enter your email to reset your password</p>
          <form className="boost_11">
            <p className="boost_12">Enter your Email Address</p>
            <div className="boost_13">
              <input type="text" placeholder="Email Address" className="boost_14" />
            </div>
            <button className="loginbtn">Reset Password</button>
          </form>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
