import React from "react";
import "../UserArea.css";

function UserFooter() {
  return (
    <footer className="admin-footer">
      <p>© 2024 Prime Authority PR. All rights reserved.</p>
    </footer>
  );
}

export default UserFooter;
