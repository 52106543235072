import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import Nav from "../../Templates/Nav";
import Footer from "../../Templates/Footer";


function ProjectDetail() {
  const { slug } = useParams(); // Get slug from the URL
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProjectDetail = async () => {
      try {
        const response = await axios.get(
          "https://primeauthoritypr.com/backend/api/getPortfolio.php", // You can create a specific API if needed for individual project
          {
            params: {
              api_key: "16d79c21-6910-4a11-a68f-37d5cfde1b86", // API key
            },
          }
        );

        // Find the project by slug in the response
        const selectedProject = response.data.find(
          (item) => item.slug === slug
        );
        setProject(selectedProject);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch project details");
        setLoading(false);
      }
    };

    fetchProjectDetail();
  }, [slug]);

  if (loading) {
    return <div>Loading project details...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!project) {
    return <div>Project not found</div>;
  }

  return (
    <>
      <Nav />
      <div className="project-detail">
        <div className="case__a">

          <div className="case__b">
            <div className="case__c">
              <img src={project.image} alt={project.name} />
            </div>
            <div className="case__d">
              <div className="case__d_1 fji" />
              <h2>{project.name}</h2>
              <p>{project.info}</p>



              <div className="case__d_2">
  {project.website && (
    <a href={project.website}>
      <img
        src="https://tuk-cdn.s3.amazonaws.com/can-uploader/imageedit_1_5656427236.png"
        alt="Website"
      />
    </a>
  )}

  {project.insta && (
    <a href={project.insta}>
      <img
        src="https://tuk-cdn.s3.amazonaws.com/can-uploader/imageedit_3_6733420918.png"
        alt="Instagram"
      />
    </a>
  )}

  {project.tiktok && (
    <a href={project.tiktok}>
      <img
        src="https://tuk-cdn.s3.amazonaws.com/can-uploader/imageedit_4_7513030197.png"
        alt="TikTok"
      />
    </a>
  )}

  {project.facebook && (
    <a href={project.facebook}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#6b4c00"
        strokeLinecap="round"
        strokeLinejoin="round"
        width={32}
        height={32}
        strokeWidth="1.5"
      >
        <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" />
      </svg>
    </a>
  )}

  {project.linkedin && (
    <a href={project.linkedin}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        stroke="#6b4c00"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        className="icon icon-tabler icons-tabler-outline icon-tabler-brand-linkedin"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" />
        <path d="M8 11l0 5" />
        <path d="M8 8l0 .01" />
        <path d="M12 16l0 -5" />
        <path d="M16 16v-3a2 2 0 0 0 -4 0" />
      </svg>
    </a>
  )}
</div>




            </div>
          </div>





          <div className="case__e">
            <div className="case__e_1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                width={32}
                height={32}
                strokeWidth="1.5"
              >
                {" "}
                <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />{" "}
                <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z" />{" "}
              </svg>
              {project.location}
            </div>
            <div className="case__e_1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                width={32}
                height={32}
                strokeWidth="1.5"
              >
                {" "}
                <path d="M10 14a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />{" "}
                <path d="M5.001 8h13.999a2 2 0 0 1 1.977 2.304l-1.255 7.152a3 3 0 0 1 -2.966 2.544h-9.512a3 3 0 0 1 -2.965 -2.544l-1.255 -7.152a2 2 0 0 1 1.977 -2.304z" />{" "}
                <path d="M17 10l-2 -6" /> <path d="M7 10l2 -6" />{" "}
              </svg>
              {project.industry}
            </div>
            <div className="case__e_1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                width={32}
                height={32}
                strokeWidth="1.5"
              >
                {" "}
                <path d="M12 3l8 4.5l0 9l-8 4.5l-8 -4.5l0 -9l8 -4.5" />{" "}
                <path d="M12 12l8 -4.5" /> <path d="M12 12l0 9" />{" "}
                <path d="M12 12l-8 -4.5" /> <path d="M16 5.25l-8 4.5" />{" "}
              </svg>
              {project.b2bc}
            </div>
          </div>

          <div className="faced">
            <h2>
              FACED <span>CHALLENGES</span>
            </h2>
            <p>{project.challenges}</p>
          </div>

          <div className="faced__a">
            <div className="faced">
              <h2>
                <span>Solutions</span> By Prime Authority PR
              </h2>
            </div>
            <div className="faced__b">
              <p>{project.solution}</p>
            </div>
          </div>

          <div className="faced__a">
            <div className="faced">
              <h2>
                <span>Results</span>
              </h2>
            </div>
            <div className="faced__b">
              <p>{project.result}</p>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="case__a pb20">
        <div className="faced">
          <h2>
            Client <span>TESTIMONIAL</span>
          </h2>
        </div>
        <div className="case__b pxextra">
          <div className="case__c">
            <div className="guybg" />
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTKsEhArl-YE3W95B09IIxX_D_i2Avswtf1VdcifUibLQ&s"
              alt="Guy"
            />
          </div>
          <div className="case__d pxextra1">
            <p>
              Prestige Perfections revolutionized my digital presence,
              propelling my business to new heights. With top-ranking Google
              search results, monthly press features, and a coveted Google
              Knowledge Panel, they've established me as a trusted authority in
              real estate. Their expertise is unmatched, and their impact is
              undeniable.
            </p>
            <svg
              width={317}
              height={1}
              viewBox="0 0 317 1"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="0.122681" width="316.122" height={1} fill="#484848" />
            </svg>
            <div className="star__a">
              <div className="star__b">
                <h3>Jason Jouan</h3>
                <h5>Serial Entrepreneur &amp; Property Investor</h5>
              </div>
              <div className="star__c">
                <a
                  className="flexsee"
                  href="https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="vidplay">See Video</span>
                  <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/imageedit_1_4843210509.png" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <Footer />
    </>
  );
}

export default ProjectDetail;
