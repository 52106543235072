import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../src/assest/Prime Authority Logo v2.png";

function Register() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      const response = await fetch("https://primeauthoritypr.com/backend/api/authenticate/register.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ 
          first_name: firstName, 
          last_name: lastName, 
          email, 
          phone_number: phoneNumber, 
          address, 
          city, 
          state, 
          country, 
          postal_code: postalCode, 
          password 
        }),
      });

      const data = await response.json();

      if (data.error) {
        setError(data.error);
      } else {
        setSuccess("Registration successful! Redirecting to login...");
        setTimeout(() => navigate("/login"), 2000); // Redirect to login page after a delay
      }
    } catch (err) {
      console.error("Registration failed:", err);
      setError("An error occurred. Please try again later.");
    }
  };

  return (
    <>
      <div className="boost">
        <div className="boost_1">
          <img src={Logo} alt="Logo" className="logo" />
        </div>
        <div className="boost_3">
          <h1 className="boost_8 s-up">Sign Up!</h1>
          <p className="boost_9">Create a New Account</p>
          {error && <p className="error-message">{error}</p>}
          {success && <p className="success-message">{success}</p>}
          <form className="boost_11 s-up-form" onSubmit={handleRegister}>
            <div className="boost_13">
              <input
                type="text"
                placeholder="First Name"
                className="boost_14"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </div>
            <br />
            <div className="boost_13">
              <input
                type="text"
                placeholder="Last Name"
                className="boost_14"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </div>
            <br />
            <div className="boost_13">
              <input
                type="text"
                placeholder="Email Address"
                className="boost_14"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <br />
            <div className="boost_13">
              <input
                type="text"
                placeholder="Phone Number"
                className="boost_14"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
            <br />
            <div className="boost_13">
              <input
                type="text"
                placeholder="Address"
                className="boost_14"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <br />
            <div className="boost_13">
              <input
                type="text"
                placeholder="City"
                className="boost_14"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </div>
            <br />
            <div className="boost_13">
              <input
                type="text"
                placeholder="State"
                className="boost_14"
                value={state}
                onChange={(e) => setState(e.target.value)}
              />
            </div>
            <br />
            <div className="boost_13">
              <input
                type="text"
                placeholder="Country"
                className="boost_14"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              />
            </div>
            <br />
            <div className="boost_13">
              <input
                type="text"
                placeholder="Postal Code"
                className="boost_14"
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
              />
            </div>
            <br />
            <div className="boost_13">
              <input
                type="password"
                placeholder="Password"
                className="boost_14"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <br />
            <div className="boost_13">
              <input
                type="password"
                placeholder="Confirm Password"
                className="boost_14"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="loginbtn">Register</button>
          </form>
          <p className="register">
            Already have an account?{" "}
            <span className="now" onClick={() => navigate("/login")}>
              Login
            </span>
          </p>
        </div>
      </div>
    </>
  );
}

export default Register;
