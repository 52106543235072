import { jwtDecode } from "jwt-decode"; // Change this to named import

export function isAdminAuthenticated() {
  const token = localStorage.getItem("token");
  if (!token) return false;

  try {
    const decoded = jwtDecode(token);
    const isTokenExpired = decoded.exp * 1000 < Date.now();
    return decoded.role === "admin" && !isTokenExpired;
  } catch (e) {
    return false;
  }
}

export function isUserAuthenticated() {
  const token = localStorage.getItem("token");
  if (!token) return false;

  try {
    const decoded = jwtDecode(token);
    const isTokenExpired = decoded.exp * 1000 < Date.now();
    return (decoded.role === "user" || decoded.role === "admin") && !isTokenExpired;
  } catch (e) {
    return false;
  }
}

export function logout() {
  localStorage.removeItem("token");
}
