import React from "react";
import Nav from "../../Templates/Nav";
import Why from "./Why";
import Publication from "./Publication";
import Time from "./Time";
import Client from "./Client";
import Testimonials from "./Testimonials";
import Get from "./Get";
import Faqs from "./Faqs";
import Footer from "../../Templates/Footer";
import Heros from "./Heros";
function Hero() {
  return (
    <>
      <div className="containerWrapper">
        <div className="elPageContentWrapper">
          <div className="elPageContent">
            <div
              data-page-element="ContentNode"
              className="pageRoot id-6Z-6K9P7D-0"
              data-slot-name="default"
            >
              <Nav />
              <Heros />
              <Why />
              <Publication />
              <Time />
              <Client />
              <Testimonials />
              <Get />
              <Faqs />
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Hero;
