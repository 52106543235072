import React, { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import UserHeader from "./Template/UserHeader";
import UserFooter from "./Template/UserFooter";

function UserArea() {
    const [userDetails, setUserDetails] = useState(null);
    const [orders, setOrders] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const token = localStorage.getItem("token");

    async function fetchUserDetails(userId) {
        console.log("Fetching user details for user ID:", userId);
        try {
            const response = await fetch(`https://primeauthoritypr.com/backend/api/admin/get_user_details.php?user_id=${userId}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const data = await response.json();
            console.log("Fetched Data:", data);
            return data;
        } catch (error) {
            console.error("Error fetching user details:", error);
            return { error: "Failed to fetch user details." };
        }
    }

    useEffect(() => {
        if (!token) {
            console.error("No token found in localStorage.");
            setLoading(false);
            return;
        }

        const decoded = jwtDecode(token);
        const userId = decoded.user_id;
        console.log("Decoded JWT:", decoded);
        console.log("User ID:", userId);

        fetchUserDetails(userId).then(data => {
            if (data.error) {
                console.error("Backend error:", data.error);
            } else {
                setUserDetails(data.user);
                setOrders(data.orders);
                setTransactions(data.transactions);
            }
            setLoading(false);
        });
    }, [token]);

    if (loading) {
        return (
            <>
                <UserHeader />
                <div className="user-area-container">
                    <h2>User Area Dashboard</h2>
                    <p>Loading user details...</p>
                </div>
                <UserFooter />
            </>
        );
    }

    return (
        <>
            <UserHeader />
            <div className="user-area-container">
                {userDetails ? (
                    <div className="user-details">
                        <div className="user-info">
                            <div><strong>Name:</strong> {userDetails.first_name} {userDetails.last_name}</div>
                            <div><strong>Email:</strong> {userDetails.email}</div>
                            <div><strong>Phone Number:</strong> {userDetails.phone_number}</div>
                            <div><strong>Address:</strong> {userDetails.address}, {userDetails.city}, {userDetails.state}, {userDetails.country}</div>
                            <div><strong>Postal Code:</strong> {userDetails.postal_code}</div>
                        </div>
                    </div>
                ) : (
                    <p>No user details found.</p>
                )}

                <h3>Orders</h3>
                <table className="orders-table">
                    <thead>
                        <tr>
                            <th>Order ID</th>
                            <th>Product Name</th>
                            <th>Price</th>
                            <th>Status</th>
                            <th>Published Link</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders.length > 0 ? (
                            orders.map(order => (
                                <tr key={order.id}>
                                    <td>{order.id}</td>
                                    <td>{order.product_name}</td>
                                    <td>${Number(order.price).toFixed(2)}</td>
                                    <td>{order.status}</td>
                                    <td>{order.published_url ? <a href={order.published_url} target="_blank" rel="noopener noreferrer">View</a> : "In Progress"}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5">No orders found.</td>
                            </tr>
                        )}
                    </tbody>
                </table>

                <h3>Transactions</h3>
                <table className="transactions-table">
                    <thead>
                        <tr>
                            <th>Transaction ID</th>
                            <th>Amount</th>
                            <th>Status</th>
                            <th>Payment Method</th>
                            <th>Created At</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transactions.length > 0 ? (
                            transactions.map(transaction => (
                                <tr key={transaction.transaction_id}>
                                    <td>{transaction.transaction_id}</td>
                                    <td>${Number(transaction.amount).toFixed(2)}</td>
                                    <td>{transaction.payment_status}</td>
                                    <td>{transaction.payment_method}</td>
                                    <td>{transaction.created_at}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5">No transactions found.</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <UserFooter />
        </>
    );
}

export default UserArea;
