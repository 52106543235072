import React, { useState, useEffect } from "react";
import Logov2 from "../../assest/Prime Authority Logo v2.png";

function Thankyou() {
  const [showScrollButton, setShowScrollButton] = useState(false);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    if (
      scrollY > windowHeight / 2 ||
      scrollY + windowHeight >= documentHeight
    ) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <div className="bgblack">
        <img src={Logov2} alt="Prime Authority Logo" />
      </div>
      <div>
        <div className="thanksbg">
          <div className="content-container">
            <h1 className="thankh1">THANK YOU FOR YOUR ORDER</h1>
            <p className="subtitle">
              We have your order... Here are the next steps:
            </p>
            <div className="step-container">
              <ul className="step-list">
                <li className="step-item">
                  <img
                    src="https://tuk-cdn.s3.amazonaws.com/can-uploader/ticks_logo---0.png"
                    className="ticksss"
                    alt="Tick mark"
                  />{" "}
                  We have received your order, and in the next 5 - 10 minutes
                  you should receive a questionnaire along with brief
                  instructions from our team.
                </li>
                <li className="step-item">
                  <img
                    src="https://tuk-cdn.s3.amazonaws.com/can-uploader/ticks_logo---0.png"
                    className="ticksss"
                    alt="Tick mark"
                  />{" "}
                  Send us a high-resolution image of you along with the filled
                  questionnaire with as much detail as possible, which you want
                  us to cover about you to showcase to your target audience your
                  specialties.
                </li>
                <li className="step-item">
                  <img
                    src="https://tuk-cdn.s3.amazonaws.com/can-uploader/ticks_logo---0.png"
                    className="ticksss"
                    alt="Tick mark"
                  />{" "}
                  Wait for the Live link within 24 hours!
                </li>
              </ul>
            </div>
            <div className="step-container">
              <h3 className="step-subtitle">
                PUBLISHED LINK AND STRATEGY SESSION
              </h3>
              <p className="step-description">
                The moment the article gets published, I will send you via email
                .....
              </p>
              <ul className="step-list">
                <li className="step-item">
                  <img
                    src="https://tuk-cdn.s3.amazonaws.com/can-uploader/ticks_logo---0.png"
                    className="ticksss"
                    alt="Tick mark"
                  />{" "}
                  The live link of your published article
                </li>
                <li className="step-item">
                  <img
                    src="https://tuk-cdn.s3.amazonaws.com/can-uploader/ticks_logo---0.png"
                    className="ticksss"
                    alt="Tick mark"
                  />{" "}
                  A calendar link to book a FREE press and reputation management
                  strategy session with me
                </li>
                <li className="step-item">
                  <img
                    src="https://tuk-cdn.s3.amazonaws.com/can-uploader/ticks_logo---0.png"
                    className="ticksss"
                    alt="Tick mark"
                  />{" "}
                  Media Kit for your website and social media channels.
                </li>
              </ul>
            </div>
            <p className="footer-text">
              Lets Make You The #1 Authority In Your Niche!
            </p>
            <p className="signature">
              Thanks,
              <br />
              <strong>
                <i>Saqib Malik</i>
              </strong>
              <br />
              Founder - Prime Authority PR
            </p>
          </div>
        </div>
        <div className="bgblack">
          {showScrollButton && (
            <button
              className="back-to-top"
              onClick={scrollToTop}
              style={{
                position: "fixed",
                bottom: "20px",
                right: "20px",
                backgroundColor: "#d4af37",
                color: "white",
                border: "none",
                borderRadius: "5px",
                padding: "10px 15px",
                cursor: "pointer",
                boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
              }}
            >
              ↑
            </button>
          )}
          <div className="footer_thanks">
            © 2024 Prime Authority PR - All rights reserved
            <img src={Logov2} alt="Prime Authority Logo" className="footer_logo" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Thankyou;
