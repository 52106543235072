import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Template/Header";
import AdminFooter from "./Template/Footer";
import "./admin.css";

function Users() {
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();

  // Fetch users from the backend API
  useEffect(() => {
    async function fetchUsers() {
      const response = await fetch("https://primeauthoritypr.com/backend/api/admin/get_users.php");
      const data = await response.json();
      setUsers(data);
    }
    fetchUsers();
  }, []);

  // Function to handle clicking on a user row
  const handleUserClick = (userId) => {
    navigate(`/admin/users/${userId}`);
  };

  // Function to handle user deletion
  const handleDeleteUser = async (userId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this user?");
    if (!confirmDelete) return;

    try {
      const response = await fetch(`https://primeauthoritypr.com/backend/api/admin/delete_user.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user_id: userId }),
      });

      if (response.ok) {
        setUsers((prevUsers) => prevUsers.filter((user) => user.id !== userId));
        alert("User deleted successfully");
      } else {
        alert("Failed to delete user");
      }
    } catch (error) {
      console.error("Error deleting user:", error);
      alert("An error occurred while deleting the user.");
    }
  };

  return (
    <>
      <Header />
      <div className="users-container">
        <table className="user-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Country</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id}>
                <td onClick={() => handleUserClick(user.id)}>{user.id}</td>
                <td onClick={() => handleUserClick(user.id)}>{user.first_name} {user.last_name}</td>
                <td onClick={() => handleUserClick(user.id)}>{user.email}</td>
                <td onClick={() => handleUserClick(user.id)}>{user.phone_number}</td>
                <td onClick={() => handleUserClick(user.id)}>{user.country}</td>
                <td>
                  <button
                    className="delete-button"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent row click event
                      handleDeleteUser(user.id);
                    }}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <AdminFooter />
    </>
  );
}

export default Users;
