import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "./Template/Header";
import AdminFooter from "./Template/Footer";

function TransactionsPage() {
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    async function fetchTransactions() {
      try {
        const response = await fetch("https://primeauthoritypr.com/backend/api/admin/get_transactions.php");
        const data = await response.json();
        setTransactions(data);
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    }
    fetchTransactions();
  }, []);

  return (
    <>
      <Header />
      <div className="transactions-container">
        {/* <h1>All Transactions</h1> */}
        <div className="transactions-table-container">
          {transactions.length > 0 ? (
            <table className="transactions-table">
              <thead>
                <tr>
                  <th>User</th>
                  <th>Transaction ID</th>
                  <th>Order ID</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Created At</th>
                </tr>
              </thead>
              <tbody>
                {transactions.map((transaction) => (
                  <tr key={transaction.transaction_id}>
                    <td>
                      <Link to={`/admin/users/${transaction.user_id}`}>
                        {transaction.first_name} {transaction.last_name}
                      </Link>
                    </td>
                    <td>{transaction.transaction_id}</td>
                    <td>
                      <Link to={`/admin/orders/${transaction.order_id}`}>
                        {transaction.order_id}
                      </Link>
                    </td>
                    <td>
                      {Number(transaction.amount)
                        ? `$${Number(transaction.amount).toFixed(2)}`
                        : "N/A"}
                    </td>
                    <td>{transaction.payment_status}</td>
                    <td>{new Date(transaction.created_at).toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No transactions available.</p>
          )}
        </div>
      </div>
      <AdminFooter />
    </>
  );
}

export default TransactionsPage;
