import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header from "./Template/Header";
import AdminFooter from "./Template/Footer";


function UserDetails() {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState(null);

  // Fetch user details from the backend API
  useEffect(() => {
    async function fetchUserDetails() {
      const response = await fetch(`https://primeauthoritypr.com/backend/api/admin/get_user_details.php?user_id=${userId}`);
      const data = await response.json();
      setUserDetails(data);
    }
    fetchUserDetails();
  }, [userId]);

  // Navigate to order details page when an order row is clicked
  const handleOrderClick = (orderId) => {
    navigate(`/admin/orders/${orderId}`);
  };

  if (!userDetails) {

    return <p>Loading...</p>;
  }

  return (
    <>
      <Header />
      <div className="user-details-container">
      
        <div className="user-info-card">
          <h3>Personal Information</h3>
          <p><strong>Name:</strong> {userDetails.user.first_name} {userDetails.user.last_name}</p>
          <p><strong>Email:</strong> {userDetails.user.email}</p>
          <p><strong>Phone Number:</strong> {userDetails.user.phone_number}</p>
          <p><strong>Address:</strong> {userDetails.user.address}, {userDetails.user.city}, {userDetails.user.state}, {userDetails.user.country}</p>
          <p><strong>Postal Code:</strong> {userDetails.user.postal_code}</p>
        </div>

        <h3>Orders</h3>
        <table className="user-orders">
          <thead>
            <tr>
              <th>Order ID</th>
              <th>Product Name</th>
              <th>Price</th>
              <th>Status</th>
              <th>Created At</th>
            </tr>
          </thead>
          <tbody>
            {userDetails.orders.map((order) => (
              <tr key={order.id} onClick={() => handleOrderClick(order.id)}>
                <td>{order.id}</td>
                <td>{order.product_name}</td>
                <td>
                  {typeof order.price === 'number' ? `$${order.price.toFixed(2)}` : 'N/A'}
                </td>
                <td>{order.status}</td>
                <td>{order.created_at}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <h3>Transactions</h3>
        <table className="user-transactions">
          <thead>
            <tr>
              <th>Transaction ID</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Payment Method</th>
              {/* <th>Card Last 4</th> */}
              <th>Created At</th>
            </tr>
          </thead>
          <tbody>
            {userDetails.transactions.map((transaction) => (
              <tr key={transaction.id}>
                <td>{transaction.id}</td>
                <td>
  {typeof transaction.amount === 'number' 
    ? `$${transaction.amount.toFixed(2)}` 
    : transaction.amount !== null 
      ? `$${parseFloat(transaction.amount).toFixed(2)}` 
      : 'N/A'}
</td>
                <td>{transaction.payment_status}</td>
                <td>{transaction.payment_method}</td>
                {/* <td>{transaction.card_last4}</td> */}
                <td>{transaction.created_at}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <AdminFooter />
    </>
  );
}

export default UserDetails;
