import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "./Template/Header";
import AdminFooter from "./Template/Footer";

function OrderDetails() {
  const { orderId } = useParams();
  const [orderDetails, setOrderDetails] = useState(null);
  const [draftUrl, setDraftUrl] = useState("");
  const [publishedUrl, setPublishedUrl] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const [transactionDetails, setTransactionDetails] = useState(null);

  useEffect(() => {
    async function fetchOrderDetails() {
      try {
        const response = await fetch(`https://primeauthoritypr.com/backend/api/admin/get_order_details.php?order_id=${orderId}`);
        const data = await response.json();
        console.log("Fetched Order Details:", data);
        setOrderDetails(data.order);
        setUserDetails(data.user);
        setTransactionDetails(data.transaction);
        setDraftUrl(data.order?.draft_url || "");
        setPublishedUrl(data.order?.published_url || "");
      } catch (error) {
        console.error("Error fetching order details:", error);
      }
    }
    fetchOrderDetails();
  }, [orderId]);

  const handleDraftApproval = async () => {
    console.log("Sending data:", { order_id: orderId, draft_url: draftUrl });
    try {
      const response = await fetch(`https://primeauthoritypr.com/backend/api/admin/approve_draft.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          order_id: orderId,
          draft_url: draftUrl,
        }),
      });
      const result = await response.json();
      console.log("Draft Approval Response:", result);
      if (result.message) {
        alert("Draft URL updated and draft approved.");
      } else {
        alert(result.error || "Failed to update draft URL.");
      }
    } catch (error) {
      console.error("Error approving draft:", error);
      alert("An error occurred while approving the draft.");
    }
  };

  const handlePublish = async () => {
    console.log("Sending data for publish:", { order_id: orderId, published_url: publishedUrl });
    try {
      const response = await fetch(`https://primeauthoritypr.com/backend/api/admin/publish_order.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          order_id: orderId,
          published_url: publishedUrl,
        }),
      });
      const result = await response.json();
      console.log("Publish Response:", result);
      if (result.message) {
        alert("Order published and client notified.");
      } else {
        alert(result.error || "Failed to publish order.");
      }
    } catch (error) {
      console.error("Error publishing order:", error);
      alert("An error occurred while publishing the order.");
    }
  };
  

  if (!orderDetails || !userDetails) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <Header />
      <div className="order-details">




        <h2>Order Details</h2>
      <table className="order-info-table">
        <tbody>
          <tr>
            <td><strong>Order ID:</strong></td>
            <td>{orderDetails.id}</td>
          </tr>
          <tr>
            <td><strong>Product Name:</strong></td>
            <td>{orderDetails.product_name}</td>
          </tr>
          <tr>
            <td><strong>Price:</strong></td>
            <td>{orderDetails.price ? `$${Number(orderDetails.price).toFixed(2)}` : "N/A"}</td>
          </tr>
          <tr>
            <td><strong>Status:</strong></td>
            <td>{orderDetails.status}</td>
          </tr>
          <tr>
            <td><strong>Created At:</strong></td>
            <td>{orderDetails.created_at}</td>
          </tr>
        </tbody>
      </table>

        <div className="draft-publish-section">
          <div className="form-group">
            <label htmlFor="draftUrl"><strong>Draft Link:</strong></label>
            <input
              type="text"
              id="draftUrl"
              value={draftUrl}
              onChange={(e) => setDraftUrl(e.target.value)}
              placeholder="Enter draft URL"
            />
            <button onClick={handleDraftApproval}>Approve Draft</button>
          </div>

          <div className="form-group">
            <label htmlFor="publishedUrl"><strong>Published Link:</strong></label>
            <input
              type="text"
              id="publishedUrl"
              value={publishedUrl}
              onChange={(e) => setPublishedUrl(e.target.value)}
              placeholder="Enter published URL"
            />
            <button onClick={handlePublish}>Publish and Notify Client</button>
          </div>
        </div>







        <h2>User Details</h2>
        <div className="user-info">
          <p><strong>Name:</strong> {userDetails.first_name} {userDetails.last_name}</p>
          <p><strong>Email:</strong> {userDetails.email}</p>
          <p><strong>Phone Number:</strong> {userDetails.phone_number}</p>
          <p><strong>Address:</strong> {userDetails.address}, {userDetails.city}, {userDetails.state}, {userDetails.country}, {userDetails.postal_code}</p>
        </div>

      <h3>Transaction Details</h3>
      <table className="transaction-info-table">
        <tbody>
          {transactionDetails ? (
            <>
              <tr>
                <td><strong>Transaction ID:</strong></td>
                <td>{transactionDetails.id}</td>
              </tr>
              <tr>
                <td><strong>Amount:</strong></td>
                <td>{transactionDetails.amount ? `$${Number(transactionDetails.amount).toFixed(2)}` : "N/A"}</td>
              </tr>
              <tr>
                <td><strong>Status:</strong></td>
                <td>{transactionDetails.payment_status}</td>
              </tr>
              <tr>
                <td><strong>Payment Method:</strong></td>
                <td>{transactionDetails.payment_method}</td>
              </tr>
              {/* <tr>
                <td><strong>Card Last 4:</strong></td>
                <td>{transactionDetails.card_last4}</td>
              </tr> */}
              <tr>
                <td><strong>Created At:</strong></td>
                <td>{transactionDetails.created_at}</td>
              </tr>
            </>
          ) : (
            <tr>
              <td colSpan="2">No transaction details available.</td>
            </tr>
          )}
        </tbody>
      </table>
    














      </div>
      <AdminFooter />
    </>
  );
}

export default OrderDetails;
