import React, { useState, useRef, useEffect } from "react";


import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

import Logo from "../../assest/Prime Authority Logo v2.png";
import Footer from "../../Templates/Footer";
import Testimonial from "../../Pages/Home/Testimonials"
import Client from "../../Pages/Home/Client";

import TheUBJSample from "../../assest/theubj.webp"

const stripePromise = loadStripe("pk_live_51PZp2AKwSFotoYf2qvL5dhnjUVqKNg7lODG2OE75oOH9C9xB1aFPgIaOycqlhxsucE8FC8e6HKh4YowLJjOEiUj600IWaTsN1n");



const DynamicSaleComponent = ({
  heading_image = "SAMPLE ARTICLE",
  show_heading_image = true,
  image = null,
  openLink = "",
  openLinkText = "",
  breakdown_heading = "Let's Break It All Down...",
  breakdown_heading2 = "HERE'S WHAT YOU ARE REALLY GETTING...",
  breakdownList = [],
  offerText = "That's $2000 worth of value for .... $250 ONLY! \n START NOW!"
}) => {
  return (
    <div className="sale_4">
      {show_heading_image && <p>{heading_image}</p>}
      {image && <img src={image} alt="Sample" />}
      {openLink && openLinkText && (
        <a 
          href={openLink}
          className="opensample"
          target="_blank"
          rel="noopener noreferrer"
        >
          {openLinkText}
        </a>
      )}

      <div className="sale_4_1">{breakdown_heading}</div>
      <div className="heres">{breakdown_heading2}</div>
      
      {breakdownList.map((item, index) => (
        <div key={index} className="sale_4_2">
          <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/ticks_1234.png" alt="Tick" />
          {item.text} {item.value && <span>({item.value})</span>}
        </div>
      ))}

      {offerText && (
        <div className="opensample" style={{ textAlign: "center" }}>
          {offerText}
        </div>
      )}
    </div>
  );
};



const CountdownTimer = ({ message, countdownDuration = 2 * 60 * 60 * 1000 }) => {
  const [timeRemaining, setTimeRemaining] = useState(getTimeRemaining());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(getTimeRemaining());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  function getTimeRemaining() {
    // Retrieve or set the initial visit time
    let initialVisitTime = localStorage.getItem('initialVisitTime');
    if (!initialVisitTime) {
      initialVisitTime = Date.now();
      localStorage.setItem('initialVisitTime', initialVisitTime);
    }

    const timeElapsed = Date.now() - initialVisitTime;

    // If more than the countdown duration has passed, reset the timer
    if (timeElapsed > countdownDuration) {
      initialVisitTime = Date.now();
      localStorage.setItem('initialVisitTime', initialVisitTime);
    }

    const remainingTime = countdownDuration - (Date.now() - initialVisitTime);
    const seconds = Math.floor((remainingTime / 1000) % 60);
    const minutes = Math.floor((remainingTime / 1000 / 60) % 60);
    const hours = Math.floor((remainingTime / (1000 * 60 * 60)) % 24);

    return {
      total: remainingTime,
      hours,
      minutes,
      seconds
    };
  }

  // If the countdown has expired, don't render anything
  if (timeRemaining.total <= 0) {
    return null;
  }

  return (
    <div className="countdown-timer">
      <h2 className="messageh2">{message}</h2>
      <div className="timer">
        <div className="time-box">
          <span>{String(timeRemaining.hours).padStart(2, '0')}</span>
          <span>Hours</span>
        </div>
        <div className="time-box">
          <span>{String(timeRemaining.minutes).padStart(2, '0')}</span>
          <span>Minutes</span>
        </div>
        <div className="time-box">
          <span>{String(timeRemaining.seconds).padStart(2, '0')}</span>
          <span>Seconds</span>
        </div>
      </div>
    </div>
  );
};


const SaleForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);

  const products = [
    {
      id: 1,
      name: "The United Business Journal Feature",
      price: 249,
      logo: "https://pbs.twimg.com/profile_images/1811635042743123968/I5lwGUwl_400x400.jpg",
    },
    {
      id: 2,
      name: "Clout News Feature",
      price: 0,
      logo: "https://yt3.googleusercontent.com/ytc/AIdro_kEiGDJwsBBxMu1tjMXUtDyNKb2iAUCtr-SGe78aygV6A0=s900-c-k-c0x00ffffff-no-rj",
    },
    {
      id: 3,
      name: "Times of New York Feature",
      price: 0,
      logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZsR4EH0E0EKGyHDjTYsHP5Z85lgAdkq61Lg&s",
    },
  ];

  const totalPrice = products.reduce((total, product) => total + product.price, 0);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
  
    if (!stripe || !elements) {
      console.error("Stripe has not loaded correctly.");
      setLoading(false);
      return;
    }
  
    const productType = products.length > 1 ? "bundle" : "single";
  
    // Concatenate all product names to create a productName for the bundle
    const productName = products.map((p) => p.name).join(", ");
  
    const formData = {
      firstName: document.getElementById("firstName").value,
      lastName: document.getElementById("lastName").value,
      email: document.getElementById("email").value,
      phoneNumber: document.getElementById("phoneNumber").value,
      address: document.getElementById("address").value,
      state: document.getElementById("state").value,
      country: document.getElementById("country").value,
      city: document.getElementById("city").value,
      postalCode: document.getElementById("postalCode").value,
      products: products.map((p) => ({ name: p.name, price: p.price * 100 })), // Send as individual products
      productType: productType,
      productName: productName, // Set concatenated product name
      price: totalPrice * 100,
    };
  
    console.log(formData);
  
    try {
      const response = await fetch("https://primeauthoritypr.com/backend/create-checkout-session.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
  
      const data = await response.json();
      console.log(data);
      const clientSecret = data.clientSecret;
      if (!clientSecret) throw new Error("Client secret not found in response");
  
      const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: `${formData.firstName} ${formData.lastName}`,
            email: formData.email,
            phone: formData.phoneNumber,
            address: {
              line1: formData.address,
              city: formData.city,
              state: formData.state,
              postal_code: formData.postalCode,
              country: formData.country,
            },
          },
        },
      });
  
      setLoading(false);
  
      if (error) {
        console.error("Payment Error:", error.message);
        alert("Payment failed. Please try again.");
      } else if (paymentIntent.status === "succeeded") {
        console.log("Payment Successful!");
  
        // Send order details to the email API
        await fetch("https://primeauthoritypr.com/backend/newOrderEmail.php", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        })
        .then(() => {
          // Call create_order.php to create the user and order
          return fetch("https://primeauthoritypr.com/backend/create_order.php", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          });
        })
        .then((orderResponse) => orderResponse.json())
        .then((orderData) => {
          if (orderData.orderId) {
            console.log("Order Created Successfully, Order ID:", orderData.orderId);
            // Redirect after order is created
            window.location.href = "/thanks";
          } else {
            alert("Order creation failed: " + orderData.error);
          }
        })
        .catch((orderError) => {
          console.error("Order creation error:", orderError);
          alert("Payment was successful, but there was an issue creating the order. Please contact support.");
        });
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
      setLoading(false);
    }
  };
  




  return (
    <div className="sale_5">
      <form className="form" onSubmit={handleFormSubmit}>
        <div className="section">
          <h2 className="c_i">Contact Information</h2>
          <div className="form-group">
            <label htmlFor="firstName">First Name</label>
            <input type="text" id="firstName" className="input-field" placeholder="First Name" required />
          </div>
          <div className="form-group">
            <label htmlFor="lastName">Last Name</label>
            <input type="text" id="lastName" className="input-field" placeholder="Last Name" required />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" className="input-field" placeholder="Enter Your Email Address" required />
          </div>
          <div className="form-group">
            <label htmlFor="phoneNumber">Phone Number</label>
            <input type="text" id="phoneNumber" className="input-field" placeholder="Enter Your phone number" required />
          </div>
        </div>

        <div className="section">
          <h2 className="c_i">Bundle Package</h2>
          {products.map((product) => (
            <div key={product.id} className="product">
              <img src={product.logo} alt={product.name} className="product-logo" />
              <div className="product-info">
                <p className="c_i">{product.name}</p>
              </div>
              <div className="product-price">
                <p>${product.price.toFixed(2)}</p>
              </div>
            </div>
          ))}
        </div>



        <div className="section">
          <h2 className="c_i">Billing Information</h2>
          <div className="form-group">
            <label htmlFor="address">Address</label>
            <input type="text" id="address" className="input-field" placeholder="Apartment, building, floor (optional)" required />
          </div>
          <div className="form-group">
            <label htmlFor="state">State</label>
            <input type="text" id="state" className="input-field" placeholder="State" required />
          </div>
          <div className="form-group">
            <label htmlFor="country">Country</label>
            <select id="country" className="input-field" required>
            <option value="US">United States</option>
              <option value="GB">United Kingdom</option>
              <option value="CA">Canada</option>
              <option value="AU">Australia</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div className="form-group half">
            <label htmlFor="city">City</label>
            <input type="text" id="city" className="input-field" placeholder="City" required />
          </div>
          <div className="form-group half">
            <label htmlFor="postalCode">Postal Code</label>
            <input type="text" id="postalCode" className="input-field" placeholder="Postal Code" required />
          </div>
        </div>

        <div className="section">
          <h2 className="c_i">Payment Information</h2>
          <div className="form-group">
            <CardElement className="input-field" options={{ style: { base: { fontSize: "16px" } } }} />
          </div>
        </div>

        <div className="section">
          <h2 className="c_i">Total: ${totalPrice.toFixed(2)}</h2>
        </div>

        <div className="submit-section">
          <button type="submit" className="submit-btn" disabled={!stripe || loading}>
            {loading ? "Processing..." : "Submit Order"}
          </button>
        </div>
        <p className="disclaimer">We Never Share Your Information With Anyone</p>
      </form>
      <div className="c_i mt3">
        <b>14 Days Guarantee</b>: You have a 14 Days money back guarantee. Don't like our service? Let us know during the first 2 weeks and you will get refunded 100% Rock Solid Guarantee.
      </div>
      <div className="c_i mt3">
        <b>Secure Payments</b>: All orders are through a very secure network. Your Credit or Debit Card information is never stored in any way. We respect your privacy.
      </div>
      <div className="c_i mt3">
        <b>Guaranteed Delivery</b>: After the payment is processed you will receive an email with a copy of a sample article and a questionnaire, which you can fill or modify outlining your story and send over for guaranteed publishing within 24 hours.
      </div>
    </div>
  );
};



// const SaleForm = () => {
//   const stripe = useStripe();
//   const elements = useElements();
//   const [loading, setLoading] = useState(false);
  
  
//   const products = [
//     {
//         name: "The United Business Journal Feature",
//         // desc: "Get a full featured Clout News article",
//         price: 0.1,
//         isBestSelling: false,
//         logo: "https://pbs.twimg.com/profile_images/1811635042743123968/I5lwGUwl_400x400.jpg",
//       },
//       // {
//       //   name: "Clout News Feature",
//       //   // desc: "Get a full featured Clout News article",
//       //   price: 0.10,
//       //   isBestSelling: false,
//       //   logo: "https://yt3.googleusercontent.com/ytc/AIdro_kEiGDJwsBBxMu1tjMXUtDyNKb2iAUCtr-SGe78aygV6A0=s900-c-k-c0x00ffffff-no-rj",
//       // },
//       // {
//       //   name: "Times of New York Feature",
//       //   // desc: "Get a full featured Clout News article",
//       //   price: 0.1,
//       //   isBestSelling: false,
//       //   logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZsR4EH0E0EKGyHDjTYsHP5Z85lgAdkq61Lg&s",
//       // },
//   ];





//   const handleFormSubmit = async (event) => {
//     event.preventDefault();
//     setLoading(true);

//     if (!stripe || !elements) {
//         console.error("Stripe has not loaded correctly.");
//         setLoading(false);
//         return;
//     }

//     // Collect form data
//     const formData = {
//         firstName: document.getElementById("firstName").value,
//         lastName: document.getElementById("lastName").value,
//         email: document.getElementById("email").value,
//         phoneNumber: document.getElementById("phoneNumber").value,
//         address: document.getElementById("address").value,
//         state: document.getElementById("state").value,
//         country: document.getElementById("country").value,
//         city: document.getElementById("city").value,
//         postalCode: document.getElementById("postalCode").value,
//         price: products[0].price * 100, // Convert to cents for Stripe
//         productName: `${products[0].name} | Prime Authority PR`
//     };

//     try {
//         // Create a PaymentIntent on your backend
//         const response = await fetch("https://primeauthoritypr.com/backend/create-checkout-session.php", {
//             method: "POST",
//             headers: {
//                 "Content-Type": "application/json",
//             },
//             body: JSON.stringify(formData),
//         });

//         const data = await response.json();

//         console.log(data);

//         // Ensure clientSecret is returned
//         const clientSecret = data.clientSecret;
//         if (!clientSecret) {
//             throw new Error("Client secret not found in response");
//         }

//         console.log("Client Secret:", clientSecret); // For debugging

//         // Confirm the payment with Stripe Elements
//         const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
//             payment_method: {
//                 card: elements.getElement(CardElement),
//                 billing_details: {
//                     name: `${formData.firstName} ${formData.lastName}`,
//                     email: formData.email,
//                     phone: formData.phoneNumber,
//                     address: {
//                         line1: formData.address,
//                         city: formData.city,
//                         state: formData.state,
//                         postal_code: formData.postalCode,
//                         country: formData.country,
//                     },
//                 },
//             },
//         });

//         setLoading(false);

//         if (error) {
//             console.error("Payment Error:", error.message);
//             alert("Payment failed. Please try again.");
//         } else if (paymentIntent.status === "succeeded") {
//             console.log("Payment Successful!");

//             // Send order details to the email API
//             await fetch("https://primeauthoritypr.com/backend/newOrderEmail.php", {
//                 method: "POST",
//                 headers: {
//                     "Content-Type": "application/json",
//                 },
//                 body: JSON.stringify(formData),
//             })
//             .then(() => {
//                 // Call create_order.php to create the user and order
//                 return fetch("https://primeauthoritypr.com/backend/create_order.php", {
//                     method: "POST",
//                     headers: {
//                         "Content-Type": "application/json",
//                     },
//                     body: JSON.stringify(formData),
//                 });
//             })
//             .then((orderResponse) => orderResponse.json())
//             .then((orderData) => {
//                 if (orderData.orderId) {
//                     console.log("Order Created Successfully, Order ID:", orderData.orderId);
//                     // Redirect after order is created
//                     window.location.href = "/thanks";
//                 } else {
//                     alert("Order creation failed: " + orderData.error);
//                 }
//             })
//             .catch((orderError) => {
//                 console.error("Order creation error:", orderError);
//                 alert("Payment was successful, but there was an issue creating the order. Please contact support.");
//             });
//         }
//     } catch (error) {
//         console.error("Error:", error);
//         alert("An error occurred. Please try again.");
//         setLoading(false);
//     }
// };



//   return (
//     <div className="sale_5">
//       <form className="form" onSubmit={handleFormSubmit}>
//         <div className="section">
//           <h2 className="c_i">Contact Information</h2>
//           <div className="form-group">
//             <label htmlFor="firstName">First Name</label>
//             <input type="text" id="firstName" className="input-field errors" placeholder="First Name" required />
//           </div>
//           <div className="form-group">
//             <label htmlFor="lastName">Last Name</label>
//             <input type="text" id="lastName" className="input-field" placeholder="Last Name" required />
//           </div>
//           <div className="form-group">
//             <label htmlFor="email">Email</label>
//             <input type="email" id="email" className="input-field" placeholder="Enter Your Email Address" required />
//           </div>
//           <div className="form-group">
//             <label htmlFor="phoneNumber">Phone Number</label>
//             <input type="text" id="phoneNumber" className="input-field" placeholder="Enter Your phone number" required />
//           </div>
//         </div>

//         <div className="section">
//           <h2 className="c_i">Select Product</h2>
//           {products.map((product, index) => (
//             <div key={index} className="product">
//               <img src={product.logo} alt={product.name} className="product-logo" />
//               <div className="product-info">
//                 {product.isBestSelling && <p className="product-title">BEST SELLING</p>}
//                 <p className="c_i">{product.name}</p>
//                 <p className="c_i">{product.desc}</p>
//               </div>
//               <div className="product-price">
//                 <p>${product.price.toFixed(2)}</p>
//               </div>
//             </div>
//           ))}
//         </div>

//         <div className="section">
//           <h2 className="c_i">Billing Information</h2>
//           <div className="form-group">
//             <label htmlFor="address">Address</label>
//             <input type="text" id="address" className="input-field" placeholder="Apartment, building, floor (optional)" required />
//           </div>
//           <div className="form-group">
//             <label htmlFor="state">State</label>
//             <input type="text" id="state" className="input-field" placeholder="State" required />
//           </div>
//           <div className="form-group">
//             <label htmlFor="country">Country</label>
//             <select id="country" className="input-field" required>
//               {/* <option value="Afghanistan">Afghanistan</option>
//               <option value="Albania">Albania</option>
//               <option value="Algeria">Algeria</option>
//               <option value="Andorra">Andorra</option>
//               <option value="Angola">Angola</option>
//               <option value="Antigua and Barbuda">Antigua and Barbuda</option> */}
//               {/* Add the rest of your country options here */}
//               <option value="United States">United States</option>
//               <option value="United Kingdom">United Kingdom</option>
//               <option value="Canada ">Canada</option>
//               <option value="Other ">Other</option>
//             </select>
//           </div>
//           <div className="form-group half">
//             <label htmlFor="city">City</label>
//             <input type="text" id="city" className="input-field" placeholder="City" required />
//           </div>
//           <div className="form-group half">
//             <label htmlFor="postalCode">Postal Code</label>
//             <input type="text" id="postalCode" className="input-field" placeholder="Postal Code" required />
//           </div>
//         </div>

//         <div className="section">
//           <h2 className="c_i">Payment Information</h2>
//           <div className="form-group">
//             <CardElement className="input-field" options={{ style: { base: { fontSize: "16px" } } }} />
//           </div>
//         </div>

//         <div className="submit-section">
//           <button type="submit" className="submit-btn" disabled={!stripe || loading}>
//             {loading ? "Processing..." : "Submit Order"}
//             <br />
//             Get Me Published Today!
//           </button>
//         </div>
//         <p className="disclaimer">We Never Share Your Information With Anyone</p>
//       </form>
//       <div className="c_i mt3">
//         <b>14 Days Guarantee</b>: You have a 14 Days money back guarantee. Don't like our service? Let us know during the first 2 weeks and you will get refunded 100% Rock Solid Guarantee.
//       </div>
//       <div className="c_i mt3">
//         <b>Secure Payments</b>: All orders are through a very secure network. Your Credit or Debit Card information is never stored in any way. We respect your privacy.
//       </div>
//       <div className="c_i mt3">
//         <b>Guaranteed Delivery</b>: After the payment is processed you will receive an email with a copy of a sample article and a questionnaire, which you can fill or modify outlining your story and send over for guaranteed publishing within 24 hours.
//       </div>
//     </div>
//   );
// };



const TheUBJSale = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const faqData = [
    {
      question: "What is the turnaround time for publishing my article?",
      answer:
        "We guarantee that your article will be published within 24 hours of submission.",
    },
    {
      question: "What happens if my article is not published within 24 hours?",
      answer:
        "We offer a 100% money-back guarantee if we fail to publish your article within the promised timeframe.",
    },
    {
      question: "Why is press coverage important for my business?",
      answer:
        "Press coverage builds credibility and authority, enhances brand visibility, and attracts potential customers. Featured articles in reputable outlets boost your reputation, provide third-party validation, and establish trust. Additionally, press coverage improves search engine rankings and drives traffic to your website, contributing to business growth and success.",
    },
    {
      question: "Who writes the articles?",
      answer:
        "After placing your order, you will receive a questionnaire to provide details about your business or personal story. Our team of experienced writers will use this information to craft compelling articles that adhere to the highest editorial standards and the specific guidelines of the news publication.",
    },
    {
      question: "Are there any long-term contracts?",
      answer:
        "No long-term contracts are required for the initial article. However, we do offer monthly retainer packages for ongoing PR and reputation management services.",
    },
    {
      question: "How can I track the status of my article?",
      answer:
        "You will receive a confirmation email once your article is published. For any inquiries, you can contact our support team at any time.",
    },
    {
      question: "Can I see examples of published articles?",
      answer:
        "Yes, we can provide samples of our previously published articles upon request. This will give you an idea of the quality and style of our work.",
    },
    {
      question: "How do I get started?",
      answer:
        "Click the 'Act Now to Secure Your Placement!' button to proceed to the secure checkout and complete your order. Our team will then contact you to gather the necessary information and begin the process.",
    },
    {
      question: "Will this feature guarantee me sales?",
      answer:
        "While press coverage significantly boosts your brand's visibility and credibility, it does not guarantee sales. It attracts potential customers and establishes trust, which are crucial factors for driving sales. However, the ultimate success depends on various factors, including your product, market demand, and overall marketing strategy.",
    },
  
    {
      question: 'What are the benefits of this offer?',
      answer: '"As Seen On" Credibility: You can proudly display "as seen on" logos on your website, enhancing your brand\'s credibility. Authority in Your Niche: Being featured in reputable news outlets positions you as a higher authority in your niche. Increased Traffic and Conversions: Press coverage boosts traffic to your website and can improve conversion rates for your business.'
    },
  
  
  ];
  

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);


  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  
  const FaqSale = () => {
    return (
      <div className="faq-section_main">
        <div className="faq-section">
          <p className="faq-header">STILL GOT QUESTIONS? WE GOT YOU!</p>
          <h2 className="faq-title">Frequently Asked Questions</h2>
          <div className="faq-container">
            {faqData.map((faq, index) => (
              <div className="faq-item" key={index}>
                <div className="faq-question">
                  <span>
                    <img
                      src="https://tuk-cdn.s3.amazonaws.com/can-uploader/question__mar-removebg-preview.png"
                      alt="?"
                      className="qmarks"
                    />{" "}
                    {faq.question}
                  </span>
                </div>
                <div className="faq-answer">{faq.answer}</div>
              </div>
            ))}
          </div>
          <br />
          <button onClick={scrollToTop} className="cta-button mt3">
            Click Here To Secure Your Placement!
          </button>
        </div>
      </div>
    );
  };
  

  return (
    <>
      <div className="bgblack">
        <img src={Logo} alt="Logo" />
      </div>
      <div className="bgsales">
        <div className="sale_1">
        <CountdownTimer 
          message="Hurry! Limited Time Offer"
          countdownDuration={4 * 60 * 60 * 1000} // 2 hours in milliseconds
        />
        <b>Limited slots available for guaranteed placements
          with Prime Authority PR.<br/>Secure your spot today and elevate your brand
          with 24hr publishing!
       



        </b>
        </div>

        <div className="sale_2">
          <h1>
            Exclusive Press Feature - Get Featured In The News Today!<br/>
            Limited Time Offer - 100% Money-Back Guarantee!
          </h1>

        {/* Left Side Content */}
          <div className="sale_3">

            <div className="sale_4">

                <DynamicSaleComponent
                heading_image="SAMPLE ARTICLEee"
                show_heading_image={false}
                image={TheUBJSample}
                openLink="https://theubj.com/rakhsinda-khanzada-empowering-common-people-promoting-global-trade/"
                openLinkText="Open Sample"
                breakdown_heading="Let's Break It All Down.."
                breakdown_heading2="HERE'S WHAT YOU ARE REALLY GETTING..."
                
                breakdownList={[
                  { text: "Custom written article and publishing, valued at $1500" },
                  { text: "A calendar link to book a free press and reputation management strategy session with me, worth $500" },
                  { text: "Higher chance of getting your social media pages marked authentic" },
                  { text: "More satisfied customers, more referrals, more free time! (You can't put a value on a better quality of life!)" },
                  { text: "Increased online visibility and brand credibility (Priceless)" },
                  { text: "A personalized media plan tailored to your business goals" },
                  { text: "24/7 customer support for any press and media-related queries" },
                  { text: "SEO-optimized content to help you rank higher in search engines" },
                  { text: "Access to exclusive insider tips on maintaining a positive public image" },
                  { text: "Opportunity to showcase your brand to a global audience" },
                  { text: "Step-by-step guidance on leveraging PR for long-term growth" }
                ]}
                
                offerText="That's $2000 worth of value for .... $250 ONLY! START NOW!"
                />
            </div>


            {/* Right Side Payment form */}
            <Elements stripe={stripePromise}>
                <SaleForm />
            </Elements>
          </div>
        </div>
      </div>
      

      <Client/>
      <Testimonial/>
      <FaqSale />
      <Footer />
    </>
  );
};

export default TheUBJSale;
