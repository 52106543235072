import React, { useEffect, useState } from "react";
import axios from "axios";

function Team() {
  // State to hold team members
  const [teamMembers, setTeamMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {

    const fetchTeamMembers = async () => {
      try {
        const response = await axios.get(
          "https://primeauthoritypr.com/backend/api/getTeam.php",
          {
            params: {
              api_key: "16d79c21-6910-4a11-a68f-37d5cfde1b86", // Using the provided API key
            },
          }
        );
        setTeamMembers(response.data);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch team members");
        setLoading(false);
      }
    };

    fetchTeamMembers();
  }, []);

  if (loading) {
    return <div>Loading team members...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div
      data-page-element="SectionContainer/V1"
      className="container fullContainer bgW100 id-6Z-zbvOKb-6520"
    >
      <div className="containerInnerV2">
        <div className="row id-6Z-zbvOKb-6521" data-slot-name="default">
          <div className="col-md-12 innerContent col_left id-6Z-zbvOKb-6522">
            <div className="col-inner">
              <div className="elSubheadlineWrapper id-6Z-zbvOKb-6523">
                <h2
                  className="elSubheadline text-center"
                  data-style-guide-subheadline="m"
                >
                  <span>WHO WE ARE</span>
                </h2>
              </div>
              <div className="elHeadlineWrapper id-6Z-zbvOKb-6526">
                <h1
                  className="elHeadline mt3 text-center"
                  data-style-guide-headline="m"
                >
                  <span>Meet The Dream Team</span>
                </h1>
              </div>
            </div>
          </div>
        </div>

        <div
          className="row id-6Z-zbvOKb-6530 whowe myrow1 mt12"
          data-slot-name="default"
        >
          {teamMembers.map((member) => (
            <div
              key={member.id}
              className="max300 innerContent col_left id-6Z-zbvOKb-6531"
            >
              <div
                className="col-inner p_team"
                data-paint-colors="lightest"
                data-style-guide-shadow="style1"
              >
                <div
                  className="elImageWrapper de-image-block"
                  alt={member.name}
                >
                  <img
                    className="elImage max300img"
                    src={member.image}
                    width={209}
                    height={208}
                    alt={member.name}
                  />
                </div>
                <div className="elHeadlineWrapper">
                  <h1 className="elHeadline" data-style-guide-headline="s">
                    <span>
                      <b>{member.name}</b>
                    </span>
                  </h1>
                </div>
                <div className="elSubheadlineWrapper">
                  <h2
                    className="elSubheadline"
                    data-style-guide-subheadline="s"
                  >
                    <span>
                      <b>{member.title}</b>
                    </span>
                  </h2>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Team;
